import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Global from './../../../../config/global';
import Header from "../../includes/header";
import Footer from "../../includes/footer";
import Loader from '../../../loader/loader';
import Popup from './popup';
import Slider from 'react-slick';
import { checkisMobile } from 'config/helper';
import { MatchList } from '../customer/matchComponent/MatchList';
import { GET } from 'config/api';




function Home(props) {

  const [showPopup, setShowPopup] = useState(true);
  const [state, setState] = useState({
    isLoading:false,
    header_slider:[],
    footer_slider:[],
    block_1:[],
    block_2:[]
  });
  const[activeEvents,setActiveEvents] = useState([]);

  useEffect(() => {
    fetchActiveEvents()
    
    const isFirstTime = !localStorage.getItem('popupShown');
    if (isFirstTime) {
      setShowPopup(true);
      localStorage.setItem('popupShown', 'true');
    }
    homePageData();
  }, [],);

  const closePopup = () => {
    setShowPopup(false);
  };

  const homePageData = async () => {
    await Axios.get(`${Global.W_BASEURL}front/home/fetchHomeData`).then((res) => {
      if (res.data.status == 200) {
        let data = res.data.data;
        let obj = {
            header_slider:[],
            footer_slider:[],
            block_1:[],
            block_2:[]
        };
        for(let item of data) {
            obj[item.type] = item?.image?.split(',');
        }

        setState(obj)
      }
    })
  }


  const settings_mini_slider = {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 1000,
        variableWidth: true,
		slidesToShow: (checkisMobile()?1:8),
		slidesToScroll: 1,
	};
    
	const settings_header_slider = {
		dots: true,
		infinite: true,
		autoplay: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

    // useEffect(() => {
	// 	fetchHomeSLider();
	// }, [],);

	// const fetchHomeSLider = async () => {
	// 	Axios.get(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
	// 		if (res.data.status == 200) {
	// 			let data = res.data.data;
	// 			stateHandler("header_slider", data.banner);
	// 		}
	// 	})
	// }

    const fetchActiveEvents = async () => {
		GET(`${Global.W_BASEURL}front/home/fetchActiveEvents`).then((res) => {
            
            if (res.data.status == 200) {
                let data = res.data.data;
                setActiveEvents(data);
			}
		})
	}

  return (

    <div>
      {showPopup && <Popup onClose={closePopup} />}
      <Header />
      {/* <Loader isLoading={state.isLoading} /> */}

            <Slider className="homeBannerSlider" {...settings_header_slider}>
				{state.header_slider.map((value) => (
					<div className="banner-slide">
						<figure><img src={`${Global.IMAGE_PATH}${value}`} alt="Banner" /></figure>
					</div>
				))}
			</Slider>
          <div className="homeImage-wrapper">
                <div className="row grid-5 gutters-5">
                    {state.block_1.map((value,index) => (
                        <div className={`col-4 ${(index==2)&& 'noneMobile-hide'}`}>
                            <div className="imgWrapper-box">
                                <img src={`${Global.IMAGE_PATH}${value}`} className="w-100" alt="Amit Design" />
                            </div>
                        </div>    
                    ))}
                    {state.block_2.map((value) => (
                        <div className="col-lg-6">
                            <div className="imgWrapper-box">
                                <img src={`${Global.IMAGE_PATH}${value}`} className="w-100" alt="Amit Design" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="topGame-section">
                <div className="homeTitle-box">
                    <h1>Top Games</h1>
                </div>
                
                <Slider className="topGame-slider" {...settings_mini_slider}>
                  {[1,1,1,1,1,1,1,1,1].map((v,i) => (
                    <div className="topGame-slide" style={{"borderRadius": "10px"}}>
                        <div className='img-holder img-cover'>
                            <figure><img style={{"borderRadius": "10px"}} src={`images/topgame${i+1}.webp`} alt="Amit Design" /></figure >
                        </div>
                    </div>
                  ))}
                </Slider>
            </div>

            <div className="homeBetting-livebox">
                <div className="pageCustom-container">
                    
            {activeEvents?.map((v,i)=>(
                <MatchList value={v}/>
            ))}
                    <div className="tableTitle-box">
                        <h4>Horse Racing</h4>
                    </div>
                        <Slider className='horsedatahome' {...settings_mini_slider}>
                        {[1,1,1,1,1,1,1,1,1].map((v,i) => (
                            <li>
                                <div className="horseData-box">
                                    <a className="btneffect">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <img src="https://images.staticcontent.io/rde/login/horse-login.svg" className="horsehomeimg" />
                                            </div>
                                            <div className="col">
                                                <div className="d-inline-block text-center horsename-title"><div>Tarbes (FRA) 23rd Sep</div></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        ))}
                        </Slider>
                </div>
                <div className="pageCustom-container">
                    <div className="tableTitle-box">
                        <h4>Greyhound Racing</h4>
                    </div>
                        <Slider className='horsedatahome' {...settings_mini_slider}>
                        {[1,1,1,1,1,1,1,1,1].map((v,i) => (
                            <li>
                                <div className="horseData-box">
                                    <a className="btneffect">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <img src="https://images.staticcontent.io/rde/login/horse-login.svg" className="horsehomeimg" />
                                            </div>
                                            <div className="col">
                                                <div className="d-inline-block text-center horsename-title"><div>Tarbes (FRA) 23rd Sep</div></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        ))}
                        </Slider>
                </div>
            </div>
            
            <Slider className="homeBannerSlider" {...settings_header_slider}>
				{state.footer_slider.map((value) => (
					<div className="homeImage-wrapper">
                        <div className="imgWrapper-box">
                            <img src={`${Global.IMAGE_PATH}${value}`} className="w-100" alt="Amit Design" />
                        </div>
                    </div>
				))}
			</Slider>
            
            <Footer />
        </div>
  );
}
export default Home;
