import React, { useState, useEffect } from 'react'

import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'

function Leftpanel(props) {
	return (
		<div className="leftMenuContainer">
			<div className="nav-wrapper">
				<div className="nav-container">
					<nav className="nav">
						<ul className="main-menu" style={{backgroundColor:'#FFFFFF',width:'100%',flexDirection:'column'}}>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/play.png' className='menu_icon' />
									In-Play
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/cricket.png' className='menu_icon' />
									Cricket
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/soccer.png' className='menu_icon' />
									Soccer
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/tennis.png' className='menu_icon' />
									Tennis
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/horse.png' className='menu_icon' />
									Horse
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/dog.png' className='menu_icon' />
									Greyhound
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/poker.png' className='menu_icon' />
									Indian Poker
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/chip.png' className='menu_icon' />
									Indian Poker II
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/aviator.png' className='menu_icon' />
									Aviator
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/poker.png' className='menu_icon' />
									AE SEXY
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/chip.png' className='menu_icon' />
									Evolution
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/poker.png' className='menu_icon' />
									Live Casino
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/chip.png' className='menu_icon' />
									Vivo
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/poker.png' className='menu_icon' />
									Betgames
								</Link>
							</li>
							<li>
								<Link to={`/UpdateProfile`} href="javascript:void(0);" className='leftNavbarText'>
									<img src='images/menu/chip.png' className='menu_icon' />
									Casino III
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	
	);

}


export default Leftpanel;
