import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';
import { GET, POST } from 'config/api';
import global from 'config/global';
import { useSelector } from 'react-redux';
import Header from 'component/web/includes/header';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function Deposit(props) {   
    const [depositStep,setDepositStep] = useState(1);
    const [depositAmount,setDepositAmount] = useState('');
    const [bankDetails,setBankDetails] = useState({});
    const [utr,setutr] = useState('');
    const [proof,setProof] = useState('');
    const [terms,setterms] = useState(false);
    const [activeAccount,setactiveAccount] = useState(0);
    const [bankList,setBankList] = useState([])
    const {buyerdata} = useSelector(state => state.buyerAuth);
	let navigate = useNavigate();


    useEffect(()=>{
        fetchBankAccountList();
    },[]);

    const fetchBankAccountList = () =>{
        let body = {
            parent_id : buyerdata.parent_id
        }

        POST(`${global.W_BASEURL}customer/transaction/fetchAccountList`,body).then((res) => {
            if(res.status == 200){
                if(res?.data?.data?.length > 0) {
                    let defaultData = res?.data?.data[0];
                    setBankDetails(defaultData);
                }
                setBankList(res?.data?.data);
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    const accountTitle = (item) =>{
        if(item == 'upi') {
            return 'UPI';
        } else {
            return 'BANK';
        }
    }
    const icon = (item) =>{
        if(item == 'upi') {
            return 'images/qr-code.png';
        } else {
            return 'images/bank.png';
        }
    }

    const validation = () =>{
        if(utr == '') {
            toast("Please Enter UTR No");
            return false;
        } else if(proof == '') {
            toast("Please Upload Screenshot of Payment");
            return false;
        } else if(depositAmount =='' || isNaN(depositAmount)) {
            toast("Please Enter Deposite Amount");
            return false;
            
        }else if(terms == false) {
            toast("Please Accept Terms & Condition");
            return false;
        }
        else {
            return true;   
        }
    }
    
    const uploadPaymentTransaction = () =>{
        if(validation()) {
            let body = {
                utr : utr,
                proof:proof,
                amount:depositAmount,
            }
    
            POST(`${global.W_BASEURL}customer/transaction/uploadPayment`,body).then((res) => {
                if(res.status == 200){
                    toast("Payment deposite Successfully please Wait");
                    navigate('/');
                } else {
                    console.log(res.data)
                }
            }).catch((err)=>{
                console.log(err)
            })
        }
         
    }

    
    const getBase64 = (target)=>{
        if(target.files.length > 0)
        {
            let file = target.files[0];
            let types = ['image/png','image/jpeg','image/jpg','image/webp']
            
            if(types.indexOf(file.type) >= 0)
            {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    setProof(reader.result);
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            }
                
        }
    }
    

    const renderAccountDetails = () =>{
        if(bankDetails?.payment_method == 'bank_account') {
            return (
                <div style={{"background": "#f3f2f6","padding": "10px","borderRadius": "15px","marginBottom": "10px"}}>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">Bank Name : </div>
                    <div className="wallet-info-detail"> {bankDetails?.bank_name}</div>
                    </div>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">A/C No : </div>
                    <div className="wallet-info-detail"> {bankDetails?.account_no}</div>
                    </div>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">IFSC Code : </div>
                    <div className="wallet-info-detail"> {bankDetails?.ifsc}</div>
                    </div>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">Account Name : </div>
                    <div className="wallet-info-detail"> {bankDetails?.account_name}</div>
                    </div>
                </div>
            )
        } else if(bankDetails?.payment_method == 'upi') {
            return (
                <div style={{"background": "#f3f2f6","padding": "10px","borderRadius": "15px","marginBottom": "10px"}}>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">UPI ID : </div>
                    <div className="wallet-info-detail"> {bankDetails?.upi_id}</div>
                    </div>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">UPI Mobile No.: </div>
                    <div className="wallet-info-detail"> {bankDetails?.upi_mobile_no}</div>
                    </div>
                    <div style={{"display": "flex"}}>
                    <div className="wallet-info-title">QR Code : </div>
                    <div className="wallet-info-detail"> {bankDetails?.upi_qr}</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    No Record Found!
                </div>
            );
        }

    }

    return (
        <div className="site-dashborad-section" >
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2><span>Deposit</span></h2>
                    </div>
                    {(depositStep == 2)?
                    <>
                            <button className='btn btn-danger' onClick={()=>{
                                setDepositStep(1);
                            }}>{'<'} Back</button>
                        <div className="container" style={{padding:'20px',borderRadius:'5px',backgroundColor:'white'}}>
                            <div className="options" style={{width:'90%',justifyContent:'center'}}>
                                {bankList?.map((v,i)=>(
                                    <div onClick={()=>{
                                        setactiveAccount(i);
                                        setBankDetails(v);
                                    }} className="option option-img-container imps" style={(activeAccount==i)?{border:'1px solid #6d081d'}:{}}>
                                        <img src={`${icon(v.payment_method)}`} alt="IMPS" />
                                        <div className="option-text">{accountTitle(v?.payment_method)}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="form-container-deposit" style={{display:'flex'}}>
                                <div className="wallet-info">
                                    {renderAccountDetails()}
                                    <button className="transfer-btn">
                                        HOW TO TRANSFER UPI TO BANK
                                        CLICK HERE WWW.UPITOBANK.COM
                                    </button>
                                    <button className="issues-btn">
                                        FOR PAYMENT RELATED ISSUES CLICK HERE
                                    </button>
                                </div>
                                <div className="payment-form">
                                <div className="formBox-deposit">
                                    <div className="payment-form-title">Unique Transaction Reference <small>*</small></div>
                                    <input onChange={({target})=>{
                                        const re = /^[0-9\b]+$/;
                                        if (target.value === '' || re.test(target.value)) {
                                            setutr(target.value);
                                         }
                                    }} type="text" value={utr} className="payment-form-input" placeholder="10 Digit USDT Reference No" />
                                </div>
                                <div className="formBox-deposit">
                                    <div className="payment-form-title">
                                    Your Payment Proof <small> [Required]</small>
                                    </div>
                                    <input onChange={({target})=>{
                                        getBase64(target)
                                    }} type="file" className="payment-form-upload" />
                                </div>
                                <div className="formBox-deposit">
                                <div className="payment-form-title">Amount <small>*</small></div>
                                    <input style={{backgroundColor:'lightgray'}} type="text" className="payment-form-amount" value={depositAmount} readonly />
                                    {/* <!-- <span style="font-weight: 500;color: green;font-size: 15px;">Rate calculation : 500 x 92 = 46000</span> --> */}
                                </div>
                                <div className="payment-form-checkbox">
                                    <input onClick={()=>{
                                        setterms(!terms);
                                    }} checked={(terms == true)} type="checkbox" id="terms-and-conditions" />
                                    <label for="terms-and-conditions">I have read and agree with the terms of payment and withdrawal policy.</label>
                                </div>
                                <hr/>
                                <button className="payment-form-submit" onClick={()=>{
                                    uploadPaymentTransaction();
                                }}>SUBMIT</button>
                                </div>
                            </div>
                        </div>
                        </>
                    :
                        <div className="">
                            <div>
                                <input value={depositAmount} onChange={({target})=>{
                                     const re = /^[0-9\b]+$/;
                                     if (target.value === '' || re.test(target.value)) {
                                        setDepositAmount(target.value);
                                     }
                                    
                                }} placeholder='Enter Deposit Amount' className='deposit_input'/>
                            </div>
                            <div style={{'display': 'flex', 'justifyContent': 'flex-end',marginRight:'240px',marginTop:'20px'}}>
                                <button className='btn btn-success btn-lg' onClick={()=>{
                                    if(depositAmount) {
                                        setDepositStep(2);
                                    }
                                }}>Submit</button>
                            </div>
                        </div>
                    }

                </div>
            </div>
            </div>
        </div>
    );

}
export default Deposit;