import React, { useState, useEffect, memo } from 'react'
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import './css/style.css';
import Global from '../../../config/global';
import Loader from '../../loader/loader';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { BuyerLogin } from './../../../redux/buyer/action';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavBarPanel from './navBarPanel';
import RightPanel from './rightPanel';
import { subscribe, unsubscribe } from 'config/event';
import moment from 'moment';


function Header({showAnnoucmentBar=true}) {

	const [isLoading, setLoading] = useState(false);
	const [showLoginModal,setLoginModal] = useState(false);
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const [state, setState] = useState({
		isLoading: true,
		headerBanner: ["header_banner1.jpg", "header_banner2.jpg"],
		sitedata: {}
	});

	const fetchUser = (username,password,loginType) => {

		if(loginType=='Demo'){
			username ='demo@gmail.com';
			password = 'password';
		}
		if(username == '') {
			toast.error("Please Enter UserName",{autoClose: 2000});
		}
		else if(password == '') {
			toast.error("Please Enter Password",{autoClose: 2000});
		} else {
			let body = {
				username:username,
				password:password
			};
			stateHandler("isLoading", true);
	
			dispatch(BuyerLogin(body)).then((res)=>{
				// console.log('ressss',res);
				stateHandler("isLoading", true);
				if(res ==200)
				{
					navigate('/dashboard');
				}
				else
				{
					toast.error("Invalid Username Or Password",{autoClose: 2000});
				}
				stateHandler("isLoading", false);
			});
		}
		
	}
	  
	useEffect(() => {
		fetchLogo();

		subscribe('showLoginBatModal',(data)=>{
			setLoginModal(true)
		});

		return ()=>{
			unsubscribe('showLoginBatModal');
		}

	}, [],);

	const fetchLogo = async () => {
		Axios.get(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				stateHandler("isLoading", false);
				stateHandler("sitedata", data.sitedata);
			}
		})
	}

	const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}


	const getWallet = () =>{
		let exposure = (buyerdata?.exposure_bal)?buyerdata?.exposure_bal:0;
		let wallet = (buyerdata?.rel_wallet)?buyerdata?.rel_wallet:0
		if(wallet){
			return(
				<div>
					<div style={{color:'#FFFFFF'}}> Wallet :₹ {(wallet - exposure)}</div>
					<div style={{color:'#FFFFFF'}}>Exposure :₹ {exposure}</div>
				</div>
			)
		} else {
			return '';
		}
	}

	return (
		<>
			{/* <Loader isLoading={state.isLoading} /> */}
			{(showAnnoucmentBar) &&
			<div className="notifcationHeader pageClass">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <i className="fa fa-bullhorn"></i>
                    </div>
                    <div className="col">
                        <marquee>{state?.sitedata?.dashboard_marque}</marquee>
                    </div>
                    <div className="col-auto">
                        <p>{moment().format('DD MMM YYYY HH:MM:SS')}</p>
                    </div>
                </div>
            </div>
        	</div>}
		<RenderLoginModal logo={`${Global.LOGO_PATH}${state?.sitedata?.logo}`} showLoginModal={showLoginModal} onClose={setLoginModal} onSubmit={(username,password,loginType)=>{
			fetchUser(username,password,loginType)
		}} />
			<header id="pageHeaderWrapper">
				<div id="pageHeader">
					<div className="headerTop">
						<div className="header-container">
							<div className="topHeaderContainer">
								<div className="logo">
									<a href="/" title="vasudevexchange">
										<img src={`${Global.LOGO_PATH}${state?.sitedata?.logo}`} alt="vasudevexchange"/>
									</a>
								</div>
								<div className="headerTopRight">
								{(!buyerdata?.id)?
								<div style={{display:'flex'}}>
									<div onClick={()=>{
										setLoginModal(true);
									}} className="loginBtn-box">
										<a href="#"><i className="fa fa-user"></i> Login</a>
									</div>
									<div onClick={()=>{
									}} className="loginBtn-box" style={{marginLeft:'10px'}}>
										<a href="/signup"><i className="fa fa-user"></i> SignUp</a>
									</div>

								</div>:
									<>
									<div className='showWalletAmount'>
										{getWallet()}
									</div>
									<RightPanel title={'Profile'} />
									</>
								}
								</div>
							</div>
						</div>
					</div>
					<NavBarPanel />
				</div>
			</header>
		</>
	);

}



const memoCompare = (prevProps, nextProps) =>{
	const { showLoginModal } = nextProps;
	const { showLoginModal : prevshowLoginModal } = prevProps;
	return (showLoginModal == prevshowLoginModal);
}


const RenderLoginModal = memo(({showLoginModal,onClose,logo,onSubmit}) =>{
	const [username, setUsername] = useState('');
  	const [password, setPassword] = useState('');

	return (
	<Modal show={showLoginModal}  dialogClassName="loginhomemodal" centered={true} onHide={onClose}>
		<div className="modal-content">
			<div className="modal-body">
				<button onClick={()=>{
					onClose(false)
				}} type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
				<img className="img-fluid" src={logo} style={{'marginBottom':'40px', "width":"240px"}} />
				<form method="POST" id="login-form">
					<input type="hidden" name="_token" value="CoYOL8odnP7n7s72k1AhiYnlc4RD58hmN2giBkUL" />
					{/* <h2>Login</h2> */}
					<div className="inputs">
						<input onChange={({target})=>{
							setUsername(target.value)
						}} type="text" className="form-control" placeholder="Email Or User Name" value={username} name="username" id="username" />
					</div>
					<div className="inputs">
						<input onChange={({target})=>{
							setPassword(target.value)
						}} type="password" className="form-control" placeholder="Enter Password" value={password} name="password" id="password" />
					</div>

					<div className="form-check" >
						<input className="form-check-input" type="checkbox" value="" id="rememberme" />
						<label className="form-check-label inputLabel" for="rememberme">
							Remember me
						</label>
					</div>
					<div className="loginModal-footer" style={{justifyContent:'center'}}>
						<div className="login-buttonGroup">
							<div className="row justify-content-center">
							<div onClick={()=>{
										onSubmit(username,password,'Login')
									}} className="col-auto">
									<div className="btn" style={{backgroundColor:'#012a30'}}>
										<a style={{color:'#FFFFFF'}} href="javascript:void(0);">Login</a>
									</div>
								</div>
								<div onClick={()=>{
										onSubmit('demo@gmail.com','password','Demo')
									}} className="col-auto">
									<div className="btn" style={{backgroundColor:'#012a30'}}>
										<a style={{color:'#FFFFFF'}} href="javascript:void(0);">Demo Login</a>
									</div>
								</div>
							</div>
						</div>
					</div>  
				</form>
			</div>
		</div>
	</Modal>
)},memoCompare);


export default Header;
