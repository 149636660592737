import React, { useState, useEffect } from 'react'
import Header from '../../includes/header';
import Footer from '../../includes/footer';
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';

import Axios from 'axios';
import Global from '../../../../config/global';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProfitLoss(props) {

    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);




    return (
        <div className="site-dashborad-section" >
           <Leftpanel /> 
           <div id="dashboard-right-content">
              <CustomerHeader /> 
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2>Profit <span>Loss</span></h2>
                    </div>

                    <div className="profit-loss-filter">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Sport</label>
                                    <div className="NiceSelect">
                                        <select>
                                            <option>All Sport</option>
                                            <option>Cricket</option>
                                            <option>Cricket</option>
                                            <option>Cricket</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Duration</label>
                                    <div className="NiceSelect">
                                        <select>
                                            <option>Yesterday</option>
                                            <option>Last Week</option>
                                            <option>Last 15 Days</option>
                                            <option>Last Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Event</th>
                                            <th>Comm IN</th>
                                            <th>Comm Out</th>
                                            <th>Amount</th>
                                            <th>Total</th>
                                            <th>Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            
                                            <th style={{'width':'50px'}}>1</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                        <tr>
                                            <th style={{'width':'50px'}}>2</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                        <tr>
                                            <th style={{'width':'50px'}}>3</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                        <tr>
                                            <th style={{'width':'50px'}}>4</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                        <tr>
                                            <th style={{'width':'50px'}}>5</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                        <tr>
                                            <th style={{'width':'50px'}}>6</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                        <tr>
                                            <th style={{'width':'50px'}}>7</th>
                                            <td><img src="images/ind-pak.jpg" className="match-logo" alt=""/> IND vs PAK</td>
                                            <td>5000</td>
                                            <td>5000</td>
                                            <td>5000.00</td>
                                            <td>20000</td>
                                            <td>Match Done</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6"></div>

                    </div>
                </div>
            </div>
        </div>
    );

}
export default ProfitLoss;