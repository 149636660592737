import React, { useState, useEffect } from 'react'

import { POST } from 'config/api';
import { truncate } from 'config/helper';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import { subscribe, unsubscribe } from 'config/event';

function CurrentBatHistory({match_id,type}) {
    library.add(faCircle);
    const dispatch = useDispatch();
    const [activeTab,setActiveTab] = useState('all')
    const [historyList,setHistoryList] = useState([]);
    const [oldhistoryList,setoldHistoryList] = useState([]);

    useEffect(()=>{
        if(match_id) {
            getBattingHistory(match_id)
            
            subscribe('refreshBatHistory',(data)=>{
                getBattingHistory(match_id)
            });
    
            return ()=>{
                unsubscribe('refreshBatHistory');
            }

        }
    },[match_id])

    const getBattingHistory = (match_id) => {
        let body = {
            index:0,
            limit:10,
            match_id:match_id,
        }
        
        POST(`${global.W_BASEURL}customer/transaction/getBattingHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;

                /**fansy loss */
                let fansy_loss = {};
                
                for(let item of data?.list){
                    let loss = 0;
                    // if(item.bat_type == 'back') {
                    //     loss = -item?.profit;
                    // } else {
                        loss = item?.loss;
                    // }

                    if(fansy_loss[item?.fansy_id]){
                        fansy_loss[item?.fansy_id] = fansy_loss[item?.fansy_id] + loss;
                    } else {
                        
                        fansy_loss = Object.assign({[item?.fansy_id]:loss},fansy_loss);
                    }
                }

                
                if(Object.keys(fansy_loss)?.length) {
                    dispatch({
                        type:'EXP_FANSY',
                        payload:fansy_loss
                    });
                }
                
                setHistoryList(data?.list)
                setoldHistoryList(data?.list);
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const filterData = (type) =>{
        setActiveTab(type);
        let batFormat = (type == 'odds')?'normal':'fansy';
        let data = [...oldhistoryList];
        if(type != 'all') {
            data = data?.filter((v,i)=>(v.bat_format == batFormat));
            setHistoryList(data);
        } else {
            setHistoryList(data);
        }
    }

    if(type == 'short') {
        return (
            <div className='batHistoryContainer'>
                <div className="tableTitle-box" style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                    <h4>Match Bet</h4>
                </div>
                <div className='col-md-12' style={{padding:'0px',marginTop:'20px'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table custom-table oddsTable">
                                    <thead> 
                                        <tr>
                                            <th>Match Bat</th>
                                            <th>Bat Type</th>
                                            <th>Odds</th>
                                            <th>Stack</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyList?.map((item,index)=>(
                                            <tr style={{textAlign:'center'}}>
                                                <td>{item?.team_name} ({item?.bat_format})</td>
                                                <td>{item?.bat_type}</td>
                                                <td>{item?.bat_rate}</td>
                                                <td>{item?.bat_stack}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='col-md-12' style={{padding:'0px',marginTop:'20px'}}>
                <div className="col-md-12 matchheading" style={{display:'block'}}>
                    <h4 className="page-header">  Bat History </h4>
                    <div >
                        <Tabs
                            defaultActiveKey={activeTab}
                            id="uncontrolled-tab-example"
                            className="mb-3 historyTab"
                            style={{display:'flex'}}
                            onSelect={(key)=>{
                                filterData(key)
                            }}
                        >
                            <Tab eventKey="all" title="All" style={{color:'#000000'}} />
                            <Tab eventKey="odds" title="ODDS" style={{color:'#000000'}} />
                            <Tab eventKey="fansy" title="Fansy" style={{color:'#000000'}} />
                        </Tabs>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table custom-table ">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Series</th>
                                        <th>Match</th>
                                        <th>Team</th>
                                        <th>Bat Type</th>
                                        <th>Stack</th>
                                        <th>Bat Format</th>
                                        <th>Result</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historyList?.map((item,index)=>(
                                        <tr style={{textAlign:'center'}}>
                                            <th style={{'width':'50px'}}>{index + 1}</th>
                                            <td>{truncate(item.series_name,25)}</td>
                                            <td>{truncate(item.match_name,25)}</td>
                                            <td>{(item.team_name)?item.team_name:'-'}</td>
                                            <td style={{color:(item.bat_type == 'back')?'#72bbef':'#faa9ba'}}>{item.bat_type}</td>
                                            <td>{item.bat_stack}</td>
                                            <td>{(item.bat_format == 'normal')?'ODDS':'Fansy'}</td>
                                            <td>{(item.result)?item.result:'Pending'}</td>
                                            <td>{moment(item.create_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>
        );
    }
    

}
export default CurrentBatHistory;