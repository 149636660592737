import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { faWallet, faHouse, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core';
import { useDispatch, useSelector } from 'react-redux';

const RightPanel = ({title}) =>{
	library.add(faWallet, faHouse, faChartSimple,faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);
	const dispatch = useDispatch();
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);

	const logout = ()=>{
		dispatch({type:'BLOGOUT'});
	}

	const getWallet = () =>{
		let exposure = (buyerdata?.exposure_bal)?buyerdata?.exposure_bal:0;
		let wallet = (buyerdata?.rel_wallet)?buyerdata?.rel_wallet:0
		if(wallet){
			return(
				<div style={{display:'flex',marginBottom:'10px'}}>
					<div style={{fontSize:15,color:'#FFFFFF',marginRight:'10px'}}>Wallet : ₹ {(wallet - exposure)}</div>
					<div style={{fontSize:15,color:'#FFFFFF'}}>Exposure : ₹ {exposure}</div>
				</div>
			)
		} else {
			return '';
		}
	}
	return (
	<Navbar  key={false} expand={false} collapseOnSelect style={{padding:'0px'}}>
		<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`}>
		<div className="loginBtn-box">
			<a href="#"><i className="fa fa-user"></i> {title}</a>
		</div>
		</Navbar.Toggle>
		<Navbar.Offcanvas
			id={`offcanvasNavbar-expand-false`}
			aria-labelledby={`offcanvasNavbarLabel-expand-false`}
			placement="end"
			className='rightPanelNavbar'
		>
			<Offcanvas.Header closeButton>
			<Offcanvas.Title style={{color:'#FFFFFF'}} id={`offcanvasNavbarLabel-expand-false`}>
				{getWallet()}
				Profile
			</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body style={{padding:'0px'}}>
			<Nav className='main-menu' style={{width:'100%',flexDirection:'column'}}>
				<Nav.Link as={Link} className='rightNavbarText' to="/dashboard">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faHouse} />
					Dashboard
				</Nav.Link>
				{/* <Nav.Link as={Link} className='rightNavbarText' to="/statements">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faBook} />
					A/C Statement
				</Nav.Link> */}
				<Nav.Link as={Link} className='rightNavbarText' to="/deposit_history">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faWallet} />
					Deposit History
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/bat_history">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faChartSimple} />
					Bat History
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/terms_conditions">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faTicket} />
					T &amp; C
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/rules">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faBookBookmark} />
					Rules
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/stackes">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faPenToSquare} />
					Edit Stakes
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/deposit">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faWallet} />
					deposit
				</Nav.Link>
				
				<Nav.Link as={Link} className='rightNavbarText' to="/withdrawal">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faWallet} />
					Withdrawal
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/profile">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faAddressCard} />
					Profile
				</Nav.Link>
				<Nav.Link as={Link} className='rightNavbarText' to="/change_password">
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faUnlockKeyhole} />
					Change Password
				</Nav.Link>
				<Nav.Link onClick={()=>{
					logout();
				}} className='rightNavbarText'>
					<FontAwesomeIcon style={{marginRight:'10px',marginLeft:'5px',fontSize:'18px'}}  icon={faRightFromBracket} />
				Log Out
				</Nav.Link>
			</Nav>
		</Offcanvas.Body>
		</Navbar.Offcanvas>
	</Navbar>
	)
}

export default RightPanel;
