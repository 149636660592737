import {BLOGIN,BLOGOUT} from './actionTypes';
import Axios from 'axios';
import global from '../../config/global';

export function BuyerLogin(body){
    return function(dispatch) {
        return Axios.post(global.W_BASEURL+'customer/auth/login',body).then((res)=>{
        //    console.log('login res',res.data.data.user_data);
            if(res.data.status==200)
            {
                dispatch({
                    type:'SITECONFIG',
                    payload:res.data.data.siteConfig
                });
                
                dispatch({
                    type:BLOGIN,
                    payload:res.data.data.user_data
                });
            }
            return res.data.status;
        }).catch((err)=>{
            console.log(err);
        });
    };  
};
 
export function Logout(){
    return function(dispatch) {
        return new Promise(function(resolve, reject) {
            dispatch({
                type:BLOGOUT,
                payload:{}
            });
            resolve(true);
        });
        
    };  
};
