import React, { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import RenderBatModal from './BatModal';
import RenderMatchItem from './Component/RenderMatchItem';


const memoCompare = (prevProps, nextProps) =>{
    
    if(prevProps?.marketOdds?.isUpdated != nextProps?.marketOdds?.isUpdated) {
        return false;
    } else {
        return true;
    }
}

const OddBat = memo((props) => {
    let marketOdds = (props?.marketOdds?.data)?props?.marketOdds?.data:{data:[]};
    let match_id = props?.match_id;
    let series_id = props?.series_id;
    let bat_format = props?.bat_format;
    let heading = props?.heading;
    let liveOdds = (props?.marketOdds?.liveOdds)?props?.marketOdds?.liveOdds:{};

    const [forceRefresh,setforceRefresh] = useState(false)
    const [showBatModal,setshowBatModal] = useState(false);
    const [modalItem,setmodalItem] = useState(false);

    return (
        <div>
            {(marketOdds?.length > 0)&&
            <div className="pageCustom-container" style={{padding:'0px'}}>
                <div className="tableTitle-box">
                    <h4>{heading}</h4>
                </div>
                
                {([1]?.length != 0)&&
                    <div className='oddBaatMob-heading'>
                        <div className='row justify-content-end'>
                            <div className='col-5'>
                                <div className='oddsSub-head'>                    
                                    <div className='innerbackheadingBtn'>
                                        Back
                                    </div>
                                    <div className='innerlayheadingBtn'>
                                        Lay
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                <div className="customTable-grid innerCustom-grid" style={{paddingBottom:'4px'}}>
                    {marketOdds?.map((value,index)=>(
                        <RenderMatchItem bat_format={bat_format} showBatModal={showBatModal} value={value} onBetClick={(item)=>{
                            setmodalItem({
                                ...item,
                                series_id:series_id,
                                match_id:match_id,
                                runnerList:marketOdds,
                            });
                            setshowBatModal(true);
                        }} forceRefresh={forceRefresh} />
                    ))}
                </div>
                {(showBatModal)&&<RenderBatModal liveBhav={liveOdds} onClose={(msg)=>{
                    setshowBatModal(false);
                    if(msg) {
                        setforceRefresh(!forceRefresh);
                    }
                }} showBatModal={showBatModal} modalItem={modalItem}/>}
            </div>}
        </div>
    )
},memoCompare)


export default OddBat
