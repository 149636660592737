import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import {POST,GET} from 'config/api';
import global from 'config/global';



const OddBat = memo(({content}) => {
    const oldOdds = useRef(null);

    const [marketRunner,setMatketRunner] = useState({});
 

    useEffect(()=>{
        if(content?.match_id) {
            // call for first time then after interval calling
            fetchMarketRunner(content?.match_id);
            let interval = setInterval(() => {
                fetchMarketRunner(content?.match_id);
            }, 1000);
            return ()=>{
                clearInterval(interval);
            }
        }
    },[]);


    const fetchMarketRunner = (match_id) =>{
       
        let body = {
            match_id:match_id,
        }

        POST(`${global.MatchAPI}thirdPartyApi/fetchOdds`,body).then((res) => {
            
            if(res.data.status == 200){
                let response = res.data?.data;
                if(response) {
                    let marketOdds = (response?.odds)?response?.odds:[];
                    let outerData = {};

                    let i = 0;
                    for(let item of marketOdds){
                        for(let item2 of item?.back_odds) {
                            if(item2?.oname == 'back1') {
                                let key = `back${i}`;
                                if(oldOdds?.current && oldOdds?.current?.[key] != item2.odds){
                                    outerData[`${key}_update`] = true;    
                                } else {
                                    outerData[`${key}_update`] = false;    
                                }

                                outerData[key] = item2.odds;
                                break;
                            }
                        }
                        for(let item2 of item?.lay_odds) {
                            if(item2?.oname == 'lay1') {
                                let key = `lay${i}`;
                                
                                if(oldOdds?.current && oldOdds?.current?.[key] != item2.odds){
                                    outerData[`${key}_update`] = true;    
                                } else {
                                    outerData[`${key}_update`] = false;    
                                }

                                outerData[key] = item2.odds;
                                break;
                            }
                        }

                        i++;
                    }

                    oldOdds.current = outerData;
                    setMatketRunner(outerData);
                }
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    return <RenderMatchItem marketRunner={marketRunner} />
})


const memoCompare = (prevProps, nextProps) =>{
    if(
        prevProps?.marketRunner?.back0 != nextProps?.marketRunner?.back0 || prevProps?.marketRunner?.lay0 != nextProps?.marketRunner?.lay0 ||
        prevProps?.marketRunner?.back1 != nextProps?.marketRunner?.back1 || prevProps?.marketRunner?.lay1 != nextProps?.marketRunner?.lay1 ||
        prevProps?.marketRunner?.back2 != nextProps?.marketRunner?.back2 || prevProps?.marketRunner?.lay2 != nextProps?.marketRunner?.lay2
    ) {
        return false;
    } else {
        return true;
    }
}

const RenderMatchItem = memo(({marketRunner={}}) =>{
    return (
        <>
        <div className='col-lg-5'>
            <div className='row gx-1'>
                <div className='col-4'>
                    <div className="col-home">
                        <div className="subGroup-table">
                            <a id="btnBack0" class={`btn-back ${(marketRunner?.back0_update == true ) ? 'colorBlinkBack':''}`} style={{cursor:'context-menu'}} side="Back">
                                <div style={{"min-height": "13px"}}>{marketRunner?.back0}</div>
                            </a>
                            <a id="btnLay0" class={`btn-lay ${(marketRunner?.lay0_update == true ) ? 'colorBlinkLay':''}`} style={{cursor:'context-menu'}} side="Lay">
                                <div style={{"min-height": "13px"}}>{marketRunner?.lay0}</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className="col-draw">
                        <div className="subGroup-table">
                            <a id="btnBack0" class={`btn-back ${(marketRunner?.back2_update == true ) ? 'colorBlinkBack':''}`} style={{cursor:'context-menu'}} side="Back">
                                <div style={{"min-height": "13px"}}>{marketRunner?.back2}</div>
                            </a>
                            <a id="btnLay0" class={`btn-lay ${(marketRunner?.lay2_update == true ) ? 'colorBlinkLay':''}`} style={{cursor:'context-menu'}} side="Lay">
                                <div style={{"min-height": "13px"}}>{marketRunner?.lay2}</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className="col-visit">
                        <div className="subGroup-table">
                            <a id="btnBack0" class={`btn-back ${(marketRunner?.back1_update == true ) ? 'colorBlinkBack':''}`} style={{cursor:'context-menu'}} side="Back">
                                <div style={{"min-height": "13px"}}>{marketRunner?.back1}</div>
                            </a>
                            <a id="btnLay0" class={`btn-lay ${(marketRunner?.lay1_update == true ) ? 'colorBlinkLay':''}`} style={{cursor:'context-menu'}} side="Lay">
                                <div style={{"min-height": "13px"}}>{marketRunner?.lay1}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
)},memoCompare);



export default OddBat
