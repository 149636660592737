import './App.css';
import WebRoute from './component/web/WebRoute';
import {Provider} from 'react-redux';
import {store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WebRoute />
        <ToastContainer hideProgressBar position="top-right" />

      </PersistGate>
    </Provider>
  );
}

export default App;
