import React, { useEffect, useState } from 'react'
import {toast} from 'react-toastify';
import color from 'config/color';
import {  useNavigate,useParams } from "react-router-dom"

import { GET, POST } from 'config/api';
import global from 'config/global';

const Signup = () => {
    const {refer_code} = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState({ 
        email: '',
        mobile: '',
        password:'',
        refercode:'',
        otp:'',
        send_otp:false,
        tnc:false,
        headerLogo:'',
        cpassword:'',
    });

    useEffect(()=>{
		fetchLogo();

      if(refer_code != ''){
        let code = refer_code?.replace('refer_code=','');
        stateHandler('refercode',code);
      }
    },[])
    /**set state value as daynamic */
    const stateHandler = (key,value)=>{
        state[key] = value;
        setState({...state});
    }

    
	const fetchLogo = async () => {
		GET(`${global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data?.data;
				stateHandler("headerLogo", data?.sitedata?.logo);
			}
		})
	}

    function validation(){
        if(state.mobile=='')
        {
            toast.error("Enter Mobile number ?");
            return false;
        }
        // else if(state.email=='')
        //   {
        //       toast.error("Enter Email Address");
        //       return false;
        //   }
        else if(state.password=='')
        {
            toast.error("Enter Password ?");
            return false;
        }
        else if(state.cpassword=='')
        {
            toast.error("Enter Confirm Password ?");
            return false;
        }
        else if(state.password != state.cpassword)
        {
            toast.error("Confirm Password Not Matched!");
            return false;
        }
        else if(state.tnc==false)
        {
              toast.error("Please Accept Terms and Conditions");
              return false;
        }
        else
        {
            return true;
        }
        
    }

    function submit(e) {
      try{
        if(validation()){
  
          let body = {
            otp:state.otp,
            // email:state.email,
            mobile:state.mobile,
            password:state.password,
            refer_code:state.refercode,
          }

            POST(`${global.W_BASEURL}customer/auth/verify_otp`, body).then((res) => {
              
                if(res.data.status == 200)
                {
                  stateHandler('send_otp',true)
                  toast("Registraion Succesfull");
                  navigate('/') 
                }
                else
                {
                  toast("OTP is invalid") 
                }
  
            }).catch((e) => {
                toast("Something Went Wrong") 
            })
          }
        }
        catch(err){
          stateHandler('send_otp',false)
         console.log(err); 
        }
    }

    
    const sendOTP = () =>{
      try{
      if(validation()){
        let body = {
          // email:state.email,
          mobile:state.mobile,
          refer_code:state.refercode,
        }

          POST(`${global.W_BASEURL}customer/auth/send_otp`, body).then((res) => {
              if(res.data.status == 200)
              {
                stateHandler('send_otp',true)
                toast("OTP Send Succesfully") 
              }
              else if(res.data.status == 201){
                toast("This mobile number is already exists");
              }
              else
              {
                toast("Something Went Wrong") 
              }

          }).catch((e) => {
              toast("Something Went Wrong") 
          })
        }
      }
      catch(err){
        stateHandler('send_otp',false)
       console.log(err); 
      }
       
    }


    return (
      <div className="h-100 w-100 d-flex justify-content-center align-items-center" style={{backgroundColor: color.header, position: 'absolute', bottom: '50px', zIndex: 1}}>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-5'>
                <div className="card bg-secondaryModal border-0 mb-0" style={{backgroundColor:'hsla(0,0%,100%,.5)'}}>
                  <img className="img-src" style={{
                      height:"155px",width:"240px", margin: "0px auto" }} src={`${global.LOGO_PATH}${state.headerLogo}`} alt="vasudevexchange" />
                  <div className="card-body admincard-body px-lg-4 py-lg-4">
                  
                    {/* {(state.send_otp) ?
                    <div className="inputs" style={{marginBottom:'20px'}}>
                      <input onChange={({target})=>{
                        stateHandler('otp',target?.value)
                      }} type="text" className="form-control" style={{backgroundColor:'#FFFFFF',color:'#000000',borderRadius:'5px'}} placeholder="Enter OTP"  value={state.otp}/>
                      
                      <div style={{marginTop:'15px',fontSize:'15px',color:'#000000',cursor:'pointer'}}>Resend OTP</div>
                      <div className="loginModal-footer" style={{marginTop:'20px',justifyContent:'center'}}>
                        <div className="login-buttonGroup">
                          <div className="row justify-content-center">
                            <div onClick={()=>{
                                submit()
                                }} className="col-auto">
                                <div className="btn" style={{backgroundColor:'#012a30'}}>
                                  <a style={{color:'#FFFFFF'}} href="javascript:void(0);">Verify OTP</a>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div> 
                    </div>
                    : */}
                      <div>
                        <div className="inputs" style={{marginBottom:'20px'}}>
                          <input onChange={({target})=>{
                            stateHandler('mobile',target?.value)
                          }} type="text" maxLength={10} className="form-control"  style={{backgroundColor:'#FFFFFF',color:'#000000',borderRadius:'5px'}} placeholder="Enter Mobile No"  value={state.mobile}/>
                        </div>
                        <div className="inputs" style={{marginBottom:'20px'}}>
                          <input onChange={({target})=>{
                          stateHandler('otp',target?.value)
                        }} type="text" disabled={(!state.send_otp)} className="form-control" style={{backgroundColor:(state.send_otp)?'#FFFFFF':'gray',color:'#000000',borderRadius:'5px'}} placeholder="Enter OTP"  value={state.otp}/>
                        </div>
                        <div className="inputs" style={{marginBottom:'20px'}}>
                          <input onChange={({target})=>{
                            stateHandler('password',target?.value)
                            }} type="password" className="form-control" style={{backgroundColor:'#FFFFFF',color:'#000000',borderRadius:'5px'}} placeholder="Enter Password"  value={state.password}/>
                        </div>
                        <div className="inputs" style={{marginBottom:'20px'}}>
                          <input onChange={({target})=>{
                            stateHandler('cpassword',target?.value)
                            }} type="cpassword" className="form-control" style={{backgroundColor:'#FFFFFF',color:'#000000',borderRadius:'5px'}} placeholder="Enter Confirm Password"  value={state.cpassword}/>
                        </div>
                        
                        <div className="inputs" style={{marginBottom:'20px'}}>
                          <input onChange={({target})=>{
                            stateHandler('refercode',target?.value)
                            }} type="text" className="form-control" style={{backgroundColor:'#FFFFFF',color:'#000000',borderRadius:'5px'}} placeholder="Enter Referral Code (Optional)"  value={state.refercode}/>
                        </div>
                        <div className="form-check" >
                          <input onClick={()=>{
                            stateHandler('tnc',!state.tnc)
                          }} className="form-check-input" checked={state.tnc} type="checkbox" value="" id="rememberme" />
                          <label onClick={()=>{
                            stateHandler('tnc',!state.tnc)
                          }}  className="form-check-label inputLabel" for="rememberme">
                            I agree to terms and conditions
                          </label>
                          {(state.send_otp) &&
                          <span style={{marginLeft:'10px',cursor:'pointer'}} onClick={()=>{
                            sendOTP();
                          }}>Resend OTP</span>
                          }
                        </div>
                      <div className="loginModal-footer" style={{marginTop:'20px',justifyContent:'center'}}>
                        <div className="login-buttonGroup">
                          <div className="row justify-content-center">
                          <div onClick={()=>{
                            if(state.send_otp){
                            submit()
                            } else {
                              sendOTP()
                            }
                              }} className="col-auto">
                              <div className="btn" style={{backgroundColor:'#012a30'}}>
                                <a style={{color:'#FFFFFF'}} href="javascript:void(0);"> {(state.send_otp)?'Submit':'Send OTP'}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </div>
                    {/* }  */}
                  </div>
                </div>
              </div>
              </div>
            </div>
        </div>
    )
}

export default Signup
