import {useState} from 'react';
require('../loader/loader.css');





function Loader({isLoading}) {
  
    if(isLoading)
    {
        return (
            <div className="mainload">
                <div className="loader">
                    <div className="dot">2</div>
                    <div className="dot">4</div>
                    <div className="dot">C</div>
                    <div className="dot">a</div>
                    <div className="dot">r</div>
                    <div className="dot">r</div>
                    <div className="dot">e</div>
                    <div className="dot">t</div>
                    <div className="cogs">
                        <div className="cog cog0">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                        <div className="cog cog1">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                        <div className="cog cog2">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else
    {
        return null;
    }
    
}

export default Loader;