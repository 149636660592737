import React, { useState, useEffect } from 'react';
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';

import { useSelector } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../loader/loader';
import { toast } from 'react-toastify';
import { updateUserPassword } from 'config/helper';
import { POST, GET } from 'config/api';
import global from '../../../../config/global';
import Header from 'component/web/includes/header';




function ChangePassword(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { buyerdata } = useSelector((state) => state.buyerAuth);
    const [currentpasssword,setCurrentPasssword] = useState('');
    const [newpassword,setNewPassword] = useState('');
    const [conpassword,setConPassword] = useState('');
    const [currentpasssword_error,setCurrentPassswordError] = useState('');
    const [newpassword_error,setNewPasswordError] = useState('');
    const [conpassword_error,setConPasswordError] = useState('');

    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);


    const updatePassword = async ()=>{
        // console.log('asdfasdf',currentpasssword,buyerdata.oldpass);
        setCurrentPassswordError('');
        setNewPasswordError('');
        setConPasswordError('');
        if(currentpasssword=='')
        {
            toast.info("Please Enter Current Passsword", { autoClose: 2000 });
            setCurrentPassswordError('Please Enter Current Passsword');
        }
        else if(currentpasssword!=buyerdata.oldpass){
            toast.info("Current Passsword Is Wrong", { autoClose: 2000 });
        }
        else if(newpassword=='')
        {
            toast.info("Please Enter New Password", { autoClose: 2000 });
            setNewPasswordError('Please Enter New Password');
        }
        else if(conpassword=='')
        {
            toast.info("Please Enter Confirm Password", { autoClose: 2000 });
            setConPasswordError('Please Enter Confirm Password');
        }
        else if(newpassword!=conpassword)
        {
            toast.info("Password MisMatch", { autoClose: 2000 });
            setConPasswordError('Password MisMatch');
        }
        else
        {
            setCurrentPassswordError('');
            setNewPasswordError('');
            setConPasswordError('');
            let body = {
                id:buyerdata.id,
                currentpasssword:currentpasssword,
                newpassword:newpassword
            };
            setIsLoading(true);
            POST(`${global.W_BASEURL}customer/auth/changeCustomerPassword`, body).then((res) => {
                if (res.data.status == 200) {
                        let updateData = {
                            id:buyerdata.id,
                            currentpasssword:currentpasssword,
                            newpassword:newpassword
                        }
                        updateUserPassword(updateData);
                        toast.info("User updated !", { autoClose: 2000 });
                        //history.goBack();


                }
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                console.log(err)
            })
        }
    }

    return (
        <>
        {/* <Loader isLoading={isLoading}/> */}
        <div>
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2>Change <span>Password</span></h2>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-5">
                            <div className="loginBox">
                                <div className="form-group">
                                    <label>Old Password</label>
                                    <input type="text" onChange={(event) => { setCurrentPasssword(event.target.value) }} className="form-control" placeholder="Enter old password"/>
                                </div>

                                <div className="form-group">
                                    <label>New Password</label>
                                    <input type="text" onChange={(event) => { setNewPassword(event.target.value) }} className="form-control" placeholder="Enter new password"/>
                                </div>

                                <div className="form-group">
                                    <label>Re-Type Password</label>
                                    <input type="text" onChange={(event) => { setConPassword(event.target.value) }} className="form-control" placeholder="Enter Re-type password"/>
                                </div>

                                <div className="link-btn">
                                    <a  onClick={() => { updatePassword() }}><i className="fa-solid fa-pen-to-square mr10"></i> Update</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    );

}
export default ChangePassword;