import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import {POST,GET} from 'config/api';
import global from 'config/global';



const ScoreEqual = (prevProps, nextProps) =>{
    const { url } = nextProps;
    const { prevurl  } = prevProps;
    return (url == prevurl);
}

const ScoreCard = memo(({match_id,sport_id}) => {
    
    return (
        <div className='scorecard'>
            <iframe src={`${global.SCORECARD}?matchId=${match_id}&sportid=${sport_id}`} name="ind vs pak"  width="100%" title="ind vs pak"></iframe>
        </div>
    )
})

export default ScoreCard
