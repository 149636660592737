import {BLOGIN,BLOGOUT} from './actionTypes';

const initialState = {
    buyerdata:{},
    siteConfig:{},
    matchData:''
};
 
export const BuyerAuthReducer = (state = initialState, action)=> {
    switch (action.type){
        case BLOGIN:
            return {
                ...state,buyerdata:action.payload
            }

        case 'MATCHDATA':
            return {
                ...state,matchData:action.payload
            }
        case 'SITECONFIG':
            return {
                ...state,siteConfig:action.payload
            }
        case BLOGOUT:
        return {
                ...state,buyerdata:{}
        }           
        default:
            return state;
    }
}