import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';
import { useDispatch, useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import color from 'config/color';
import Loader from '../../../loader/loader';
import Header from 'component/web/includes/header';



function Profile(props) {
    const [isLoading, setIsLoading] = useState(false);
	const {buyerdata,siteConfig} = useSelector((state) => state.buyerAuth);
    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);




    return (
        <>
        <div>
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2>My <span>Profile</span></h2>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="table-responsive">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th colspan="2">User Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style={{'width':'200px'}}>Full Name</th>
                                            <td>{buyerdata.name}</td>
                                        </tr>
                                        <tr>
                                            <th>User Name</th>
                                            <td>{buyerdata.user_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{buyerdata.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Mobile :</th>
                                            <td>{buyerdata.mobile}</td>
                                        </tr>
                                        <tr>
                                            <th>Address :</th>
                                            <td>{buyerdata.address}</td>
                                        </tr>
                                        
                                        <tr>
                                            <th>Wallet :</th>
                                            <td>{buyerdata.wallet}</td>
                                        </tr>
                                        <tr>
                                            <th>Exposure :</th>
                                            <td>{(buyerdata.exposure_bal>0)?' - '+buyerdata.exposure_bal:0}</td>
                                        </tr>
                                        <tr>
                                            <th>Join Date</th>
                                            <td>{buyerdata.join_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="link-btn">
                                    <Link to={`/UpdateProfile`} href="javascript:void(0);" ><i className="fa-solid fa-floppy-disk mr10" /> Update Profile</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>

                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );

}
export default Profile;