import React, { memo, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTv,faCircle, faL} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { POST } from 'config/api';

import global from 'config/global';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import OddBatOuter from './../bat/oddBatOuter';
import { encrypt } from 'config/helper';
import { useDispatch, useSelector } from 'react-redux';
import { emit } from 'config/event';



export const MatchList = ({value={}}) =>{
	const dispatch = useDispatch()

    library.add(faTv,faCircle);
    let history = useNavigate();
	const buyerdata = useSelector((state) => state?.buyerAuth?.buyerdata);
    
    const [matchList,setMatchList] = useState([]);

    useEffect(()=>{
        if(value?.event_id){
            fetchMatchList(value);
        }
    },[]);



    const fetchMatchList = (value) => {
        
        let body ={
            sport_id:value.event_id
        }
        
        POST(`${global.W_BASEURL}front/home/fetchMatchList`,body).then((res)=>{
            if(res.data.status == 200) {
                setMatchList(res.data.data);
            } else {
                setMatchList([]);
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const handleMatchClick = (content) =>{
        if(buyerdata?.token){

            const {series_id,match_id,name,openDate,series_name,sport_Id,timezone} = content;

            let body = {
                match_name:name,
                openDate:openDate,
                match_id:match_id,
                series_id:series_id,
                sport_id:sport_Id,
                timezone:timezone,
                series_name:series_name
            }
                
            let encryptedItem = encrypt(body);
            dispatch({
                type:'MATCHDATA',
                payload:encryptedItem
            });
            history(`/matchbat`);
        } else {
            emit('showLoginBatModal',true);
        }
    }


    const makeMatchDate = (date,timezone) =>{
        try{
            if(date) {
                let d = moment(date).utc(timezone);
                return d.format('DD MMM HH:mm');
            } else {
                return '';
            }
        } catch(err){
            console.log(err)
        }
        
    }

    const matchDate = (date,timezone) =>{
        if(date) {
            let d = moment(date).utc(timezone).unix();
            let cdate = moment().unix();
            if(d <= cdate) {
                return true;
            } else {
                return false
            }
        } else {
            return false;
        }
    }
    return (
        <div>
        {(matchList?.length > 0)&&
        <div className="">
        <div className="tableTitle-box">
            <h4>{value?.name}</h4>
        </div>
        <div className="customTable-grid mb5">
            <div className="priceShow-box">
                {matchList?.map((item,index)=>(
                    <div className='matchList-grid'>
                        <div className='row align-items-center'>
                            <div className='col-lg-7' onClick={()=>{
                                handleMatchClick(item);
                            }} style={{'cursor': 'pointer'}}>
                                
                                <div className="tableFirst-col">
                                    <div className="tableEvent-title"><span className="dtime">{makeMatchDate(item?.openDate,item?.timezone)} |</span> {(matchDate(item?.openDate,item?.item?.timezone))&&<FontAwesomeIcon size='2xs' color='green' icon={faCircle} />} {item?.name} {(matchDate(item?.openDate,item?.item?.timezone))&& <span style={{fontSize:'15px',color:'#18adc5'}}>Live Now</span>} </div>
                                    <div className="tableEvent-status">
                                        <img src="images/tv.png" alt="Amit Design"/>
                                        {(item?.bm == 'true') && <img src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg" alt="Amit Design"/>}
                                        {(item?.fancy == 'true') && <img src="https://images.staticcontent.io/rde/uimg/fancy-icon1.svg" alt="Amit Design"/>}
                                    </div>
                                </div>
                                <div className='mobileDate-view'>
                                    <span><i class="flaticon-clock"></i> <span>{makeMatchDate(item?.openDate,item?.timezone)}</span> | {item?.series_name}</span>
                                </div>
                            </div>
                            <OddBatOuter content={item} />
                        </div>
                    </div>
                    ))}
                <div>
                    <div colspan="4" className="text-end">
                        <a href="#" className="tableView-btn">View All...</a>
                    </div>
                </div>
            </div>
        </div>
    </div>}
    </div>
    );
};