import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import RenderBatModal from './BatModal';



const FansyBat = (props) => {
    let marketOdds = (props?.marketOdds)?props?.marketOdds?.data:[];
    let match_id = props?.match_id;
    let series_id = props?.series_id;
    let liveOdds = (props?.marketOdds?.liveOdds)?props?.marketOdds?.liveOdds:{};

    const [showBatModal,setshowBatModal] = useState(false);
    const {fansyData} = useSelector(state => state.buyerGlobal);
    const [modalItem,setmodalItem] = useState({});

    const {
        batPlace=()=>{},
    } = props;
    
    return (
        <div>
            
            {(marketOdds?.length > 0)&&
            <div className="pageCustom-container" style={{padding:'0px'}}>
            <div className="tableTitle-box">
                <h4>Fansy</h4>
            </div>
            <div className="customTable-grid">
                <div className="">
                {([1]?.length != 0)&&
                    <div className='oddBaatMob-heading fancyBaat-heading' style={{paddingBottom:'5px'}}>
                        <div className='row justify-content-end'>
                            <div className='col-4'>
                                <div className='oddsSub-head'>                    
                                    <div className='innerlayheadingBtn'>
                                        No
                                    </div>
                                    <div className='innerbackheadingBtn'>
                                        Yes
                                    </div>
                                </div>
                            </div>
                            <div className='col-1'></div>
                        </div>
                    </div>
                }
                <div className="customTable-grid innerCustom-grid fancyGroup-mob">
                            {marketOdds?.map((value,index)=>(
                                <div className='matchList-grid'>
                                    <div className='row align-items-center'>
                                        <RenderFansy fansyData={fansyData} index={index} value={value} onBetClick={(item)=>{
                                            setmodalItem({
                                                ...item,
                                                match_id:match_id,
                                                series_id:series_id,
                                            });
                                            setshowBatModal(true);
                                        }} />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {(showBatModal)&&<RenderBatModal liveBhav={liveOdds} onPlace={batPlace} onClose={()=>{
                setshowBatModal(false)
            }} showBatModal={showBatModal} modalItem={modalItem}/>}

            </div>}
        </div>   
    )
}




const memoCompare = (prevProps, nextProps) =>{
    let value = prevProps?.value;
    let nvalue = nextProps?.value;
    if((value?.gstatus != nvalue?.gstatus) || (value?.isUpdated != nvalue?.isUpdated) || (prevProps?.fansyData?.[value?.sid] != nextProps?.fansyData?.[value?.sid])) {
        return false;
    } else {
        return true;
    }
}

const RenderFansy = memo(({value,fansyData={},onBetClick}) =>{
    
    const checkBatIsSuspended = () =>(value?.gstatus == 'SUSPENDED' || value?.gstatus == 'Ball Running');

    const onPress = ({type,odds,size}) =>{
        if(!checkBatIsSuspended())
        {
            
            let content = {
                runnerName:value?.team_name,
                fansy_id:value?.sid,
                batRate:odds,
                batSize:size,
                battype:type,
                bat_format:'fansy', 
            }
            onBetClick(content)
        }
    }


let back_odds = (value?.back_odds && value?.back_odds?.length) ? value?.back_odds[0]:{}
let lay_odds = (value?.lay_odds && value?.lay_odds?.length) ? value?.lay_odds[0]:{};


return(
    <>
        <div className='col-7 custom-col-7' style={{'cursor': 'pointer'}}>
            <div className="tableFirst-col">
                <div className="tableEvent-title">{value?.team_name} <span style={{color:'red'}}>{(fansyData?.[value?.sid]) && `-${Math.abs(fansyData[value?.sid])}`}</span> </div>
            </div>
            <div className='maxMin-price mobileDate-view'>Min 25k  MAX 250K </div>
        </div>
        
        <div className='col-4'>
            <div className={`${(checkBatIsSuspended()) && 'btnOverlay1'} fancyData-view`}>
                {(checkBatIsSuspended())&&
                    <div className='overlayText'>{value?.gstatus}</div>
                }
                <div className='row gx-2'>
                    <div className='col-6'>
                        <div className="col-draw groupTable-price">
                            <div className="subGroup-table">
                                <a onClick={()=>{
                                    if(!checkBatIsSuspended() && lay_odds?.odds > 0){onPress({type:'lay',odds:lay_odds?.odds,size:lay_odds?.size})}
                                }}  className={`btn-lay `} style={{padding:'2px'}}><div>{lay_odds?.odds} <div className='batUser'>  {lay_odds?.size} </div></div></a>    
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="col-draw groupTable-price">
                            <div className="subGroup-table">
                            <a onClick={()=>{
                                if(!checkBatIsSuspended() && back_odds?.odds > 0){onPress({type:'back',odds:back_odds?.odds,size:back_odds?.size})}
                            }}  className={`btn-back `} style={{padding:'2px'}}><div>{back_odds?.odds} <div className='batUser'>  {back_odds?.size} </div></div></a>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { <div className='col-1'>
            <div className='maxMin-price'>Min 25k <br /> MAX 250K </div>
        </div> }
        </>
    // <tr className='fansymobile'>
    //         <td style={{width:'50%'}}>
    //             <div className="tableFirst-col">
    //                 <div className="innertableEvent-title" style={{cursor:'context-menu'}}><span className="dtime">{value?.RunnerName}</span> <span style={{color:'red'}}>{(fansyData?.[value?.selectionId]) && `-${Math.abs(fansyData[value?.selectionId])}`}</span> </div>
    //             </div>
    //         </td>
    //         <div className='btnOverlay1'>
    //         <td className="col-home" style={{padding:'2px'}}>
    //             <div className="innersubGroup-table" style={{justifyContent:'end'}}>
    //         <a onClick={()=>{
    //             if(!checkBatIsSuspended() && value?.LayPrice1 > 0){onPress('lay')}
    //         }}  className={`btn-lay0 ${(checkBatIsSuspended())?'suspended':''}`} style={{padding:'2px'}}>{(checkBatIsSuspended())?value?.GameStatus:<div>{value?.LayPrice1} <div className='batUser'>  {value?.LaySize1} </div></div>}</a>    
    //         </div>
    //         </td>
    //         <td className="col-home" style={{padding:'2px'}}>
    //             <div className="innersubGroup-table">
    //             <a onClick={()=>{
    //                 if(!checkBatIsSuspended() && value?.BackPrice1 > 0){onPress('back')}
    //             }}  className={`btn-back2 ${(checkBatIsSuspended())?'suspended':''}`} style={{padding:'2px'}}>{(checkBatIsSuspended())?value?.GameStatus:<div>{value?.BackPrice1} <div className='batUser'>  {value?.BackSize1} </div></div>}</a>    
    //         </div>
    //         </td>
    //     </div>
    // </tr>
)},memoCompare);

export default FansyBat

