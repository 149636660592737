import React, { useState, useEffect } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../loader/loader';
import Marquee from "react-fast-marquee";
import Dropdown from 'react-bootstrap/Dropdown';
import { drawerOpenClose } from 'config/helper';
	

function CustomerHeader(props) {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		isLoading: false
	});
	const {buyerdata,siteConfig} = useSelector((state) => state.buyerAuth);

	// console.log('custdataa',buyerdata);
	library.add(faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);

	return (
		<>
			{/* <Loader isLoading={state.isLoading} /> */}
			<div className="deshborad-headerBox">
				<div className="row align-items-center">
					<div className="col-auto mob-sidebar">
						<button onClick={()=>{
							drawerOpenClose()
						}} type="button" className="btn sidebarCollapse sidebar-open">
								<FontAwesomeIcon icon="bars" />
						</button>
					</div>
					<div className="col">
						<div className="user-balance-box">
							<ul className="clearfix">
								<li>
									<h5><strong>Main: </strong> {buyerdata.wallet} PTI</h5>
								</li>
								<li>
									<h5><strong>Exp</strong> <span> {(buyerdata.exposure_bal>0)?' - '+buyerdata.exposure_bal:0}</span></h5>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-auto">
						<div className="progile-header">
							<div className="dropdown">
								{/* <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
									<img src="images/user.png" className='userProfilehide' alt="" /> {buyerdata.name}
								</button> */}
								<Dropdown data-bs-theme="dark">
									<Dropdown.Toggle variant="none" id="dropdown-basic">
										<img src="images/user.png" className='userProfilehide' alt="" /> {buyerdata.name}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item href="ChangePassword.html">Change Password</Dropdown.Item>
										<Dropdown.Item onClick={()=>{
											dispatch({
												type:'BLOGOUT'
											})
										}}>Logout</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="dashboard-marque">
				<Marquee>{siteConfig.dashboard_marque}</Marquee>
			</div>
		</>
	);

}

export default CustomerHeader;
