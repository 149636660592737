import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';

import { POST } from 'config/api';
import { truncate } from 'config/helper';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from 'component/web/includes/header';

function DepositHistory(props) {
    library.add(faCircle);
    const [historyList,setHistoryList] = useState([]);

    useEffect(()=>{
        getBattingHistory()
    },[])
    const getBattingHistory = () => {
        let body = {
            index:0,
            limit:10,
        }
        POST(`${global.W_BASEURL}customer/transaction/getDepositHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setHistoryList(data?.list)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }
    // getBattingHistory

    // const getStatusColor = (status) =>{
    //     switch (status) {
    //         case 'success':
    //             return '#1e9c13';
    //             break;
    //         case 'pending':
    //             return '#e35327';
    //             break;
    //         case 'cancel':
    //             return '#9e0909';
    //             break;
    //         default:
    //             return '#FFFFFF';
    //             break;
    //     }
    // }
    return (
        <div>
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2><span>Deposit History</span></h2>
                    </div>

                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Txn Id</th>
                                            <th>UTR</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyList?.map((item,index)=>(
                                            <tr style={{textAlign:'center'}}>
                                                <th style={{'width':'50px'}}>{index + 1}</th>
                                                <td>{item?.id}</td>
                                                <td>{item?.utr_no}</td>
                                                <td>{item?.amount}</td>
                                                <td >{item?.status}</td>
                                                <td>{moment(item?.created_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );

}
export default DepositHistory;