import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';

import { POST } from 'config/api';
import { truncate } from 'config/helper';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from 'component/web/includes/header';

function Statements(props) {
    library.add(faCircle);
    const [historyList,setHistoryList] = useState([]);

    useEffect(()=>{
        getBattingHistory()
    },[])
    const getBattingHistory = () => {
        let body = {
            index:0,
            limit:10,
        }
        POST(`${global.W_BASEURL}customer/transaction/getBattingHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setHistoryList(data?.list)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }
    // getBattingHistory

    return (
        <div>
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2><span>Bat History</span></h2>
                    </div>

                    {/* <div className="profit-loss-filter">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Sport</label>
                                    <div className="NiceSelect">
                                        <select>
                                            <option>All Sport</option>
                                            <option>Cricket</option>
                                            <option>Cricket</option>
                                            <option>Cricket</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Duration</label>
                                    <div className="NiceSelect">
                                        <select>
                                            <option>Yesterday</option>
                                            <option>Last Week</option>
                                            <option>Last 15 Days</option>
                                            <option>Last Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Series</th>
                                            <th>Match</th>
                                            <th>Team</th>
                                            <th>Bat Type</th>
                                            <th>Stack</th>
                                            <th>Bat Format</th>
                                            <th>Result</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyList?.map((item,index)=>(
                                            <tr style={{textAlign:'center'}}>
                                                <th style={{'width':'50px'}}>{index + 1}</th>
                                                <td>{truncate(item.series_name,25)}</td>
                                                <td>{truncate(item.match_name,25)}</td>
                                                <td>{(item.team_name)?item.team_name:'-'}</td>
                                                <td style={{color:(item.bat_type == 'back')?'#72bbef':'#faa9ba'}}>{item.bat_type}</td>
                                                <td>{item.bat_stack}</td>
                                                <td>{(item.bat_format == 'normal')?'ODDS':'Fansy'}</td>
                                                <td>{(item.result)?item.result:'Pending'}</td>
                                                <td>{moment(item.create_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );

}
export default Statements;