import Home from './view/front/home';
import Signup from './view/front/signup';
import Login from './view/front/login';
import Aboutus from './view/front/about_us';
import Dashbord from './view/customer/dashboard/dashbord';
import Matchbat from './view/customer/bat/matchbat';
import MultiMarker from './view/customer/multiMarker';
import ChangePassword from './view/customer/changepassword';
import Profile from './view/customer/Profile';
import UpdateProfile from './view/customer/UpdateProfile';
import ProfitLoss from './view/customer/ProfitLoss';
import Rule from './view/customer/Rule';
import Stackes from './view/customer/Stackes';
import Statements from './view/customer/Statement';
import DepositHistory from './view/customer/depositHistory';
import BatHistory from './view/customer/bat_history';
import Terms from './view/customer/Terms';
import Deposit from './view/customer/deposit';
import Withdrawal from './view/customer/withdrawal';





import React from 'react'
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom'
import {Middleware} from './middleware/middleware';
function WebRoute() {
  const Auth = Middleware.Auth();
  return (
    <Router>
        <Routes>
          {/* <Route exact path="/" element={<Home/>}/> */}
          {/* <Route exact path="/home" element={<Home/>}/> */}
          {/* <Route exact path="/index" element={<Home/>}/> */}
          <Route exact path="/signup" element={<Signup/>}/>
          <Route exact path="/signup/:refer_code" element={<Signup/>}/>
          <Route exact path='/' element={Auth ? <Dashbord /> : <Home/>  } />
          <Route exact path='/index' element={Auth ? <Dashbord /> : <Home/>  } />
          <Route exact path='/home' element={Auth ? <Dashbord /> : <Home/>  } />
          <Route exact path="/about-us.html" element={<Aboutus/>}/>
          <Route exact path='/dashboard' element={Auth ? <Dashbord /> : <Navigate to='/' />  } />
          <Route exact path='/matchbat' element={Auth ? <Matchbat /> : <Navigate to='/' />  } />
          <Route exact path="/multiMarker" element={Auth ? <MultiMarker /> : <Navigate to='/' />  } />
          <Route exact path="/change_password" element={Auth ? <ChangePassword /> : <Navigate to='/' />  } />
          <Route exact path="/profile" element={Auth ? <Profile /> : <Navigate to='/' />  } />
          <Route exact path="/UpdateProfile" element={Auth ? <UpdateProfile /> : <Navigate to='/' />  } />
          <Route exact path="/ProfitLoss" element={Auth ? <ProfitLoss /> : <Navigate to='/' />  } />
          <Route exact path="/rules" element={Auth ? <Rule /> : <Navigate to='/' />  } />
          <Route exact path="/stackes" element={Auth ? <Stackes /> : <Navigate to='/' />  } />
          {/* <Route exact path="/statements" element={Auth ? <Statements /> : <Navigate to='/' />  } /> */}
          <Route exact path="/deposit_history" element={Auth ? <DepositHistory /> : <Navigate to='/' />  } />
          <Route exact path="/bat_history" element={Auth ? <BatHistory /> : <Navigate to='/' />  } />
          <Route exact path="/deposit" element={Auth ? <Deposit /> : <Navigate to='/' />  } />
          <Route exact path="/withdrawal" element={Auth ? <Withdrawal /> : <Navigate to='/' />  } />
          <Route exact path="/terms_conditions" element={Auth ? <Terms /> : <Navigate to='/' />  } />
        </Routes>
    </Router>

  );
}

export default WebRoute;
