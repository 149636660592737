import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {useDispatch,useSelector } from 'react-redux';
import { decrypt } from 'config/helper';
import OddBat from './oddBat';
import FansyBat from './fansybat';
import Leftpanel from 'component/web/includes/leftpanel';
import CurrentBatHistory from './CurrentBatHistory';
import { POST } from 'config/api';
import global from 'config/global';
import { subscribe, unsubscribe } from 'config/event';
import Header from 'component/web/includes/header';
import moment from 'moment-timezone';
import ScoreCard from './ScoreCard';
import RenderTv from './Component/RenderTV';

const MatchBat = (props) => {
    const dispatch = useDispatch()
    const {matchData:reduxMatchData} = useSelector(state => state.buyerAuth);
    const [matchData,setMatchData] = useState({});
    const [showTv,setshowTv] = useState(false);
    const [marketOdds,setMarketOdds] = useState({})
    const oldOdds = useRef({});

    useEffect(()=>{
        if(reduxMatchData) {
            let response = decrypt(reduxMatchData);
            let match_id = response?.match_id;

            let interval = setInterval(() => {
                fetchMarketRunner(match_id);
            }, 1000);
            
            setMatchData(response)
            getExposureItem(match_id)
            
            subscribe('refreshExposure',(data)=>{
                getExposureItem(match_id)
            });
    
            return ()=>{
                clearInterval(interval);
                unsubscribe('refreshExposure');
            }
        }        
    },[]);


    const fetchMarketRunner = (match_id) =>{
       
        let body = {
            match_id:match_id,
        }

        POST(`${global.MatchAPI}thirdPartyApi/fetchOdds`,body).then((res) => {
            
            if(res.data.status == 200){
                let response = res.data?.data;
                if(response) {
                    let data = checkValueIsChange(response);
                    setMarketOdds(data)
                }
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    
    const getExposureItem = (match_id) => {
        let body = {
            match_id:match_id,
        }
        
        POST(`${global.W_BASEURL}customer/transaction/getExposureItem`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                let obj = {}
                
                for(let item of data) {
                    let newObj = {[item.bat_format]:{exposure_id:item?.id,exposure_obj:JSON.parse(item?.exposure_obj)}};
                    obj = Object.assign(newObj,obj);
                };
                dispatch({type:'EXP_DATA',payload:obj});
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    
    const checkValueIsChange = (data) =>{
        let runnerObj = {...data};
        let newRunnerObj = {};

        for(let runnerKey in runnerObj) {
            let bhav = {};

            let runnerData = runnerObj[runnerKey];
            let isUpdated = false;
            bhav = Object.assign(bhav,{[runnerKey]:{}});

            if(runnerData?.length) {
                let obj = {};
                let isNew = (oldOdds?.current?.[runnerKey] == undefined);
        
                for(let item of runnerData) {
                    let back_i = 0;
                    let lay_i = 0;
                    let sid = item.sid;
                    
                    /* set live bhav in context */
                    bhav[sid] = {back:{},lay:{}};
        
                    for(let item2 of item?.back_odds) {
                        /* set live bhav in context */
                        if(runnerKey == 'fansy') {
                            if(back_i == 0) { 
                                bhav[sid]['back'] = item2;
                            }
                        } else {
                            if(back_i == 2) { 
                                bhav[sid]['back'] = item2;
                            }
                        }
                        
                        
                        if(!isNew) {
                            // this for prevent blink odds box
                            item2.isUpdated = (oldOdds?.current?.[runnerKey][`${sid}_back_${back_i}`] != item2.odds || oldOdds?.current?.[runnerKey][`${sid}_back_${back_i}_size`] != item2.size);
                            // this for prevent re render odd block row
                            item.isUpdated = item2.isUpdated;
                            // this for prevent re render runner row
                            isUpdated = item2.isUpdated;
                        }
                        
                        obj[`${sid}_back_${back_i}`] = item2.odds;
                        obj[`${sid}_back_${back_i}_size`] = item2.size;
                        back_i++;
                    }
                    for(let item2 of item?.lay_odds) {
                        /* set live bhav in context */
                        if(lay_i == 0) { 
                            bhav[sid]['lay'] = item2;
                        }
                        
                        if(!isNew) {
                            // this for prevent blink odds box
                            item2.isUpdated = (oldOdds?.current?.[runnerKey][`${sid}_lay_${lay_i}`] != item2.odds || oldOdds?.current?.[runnerKey][`${sid}_lay_${lay_i}_size`] != item2.size);
                            // this for prevent re render odd block row
                            item.isUpdated = item2.isUpdated;
                            // this for prevent re render runner row
                            isUpdated = item2.isUpdated;
                        }
                        obj[`${sid}_lay_${lay_i}`] = item2.odds;
                        obj[`${sid}_lay_${lay_i}_size`] = item2.size;
                        lay_i++;
                    }
                    
                }
        
                /* end  set live bhav in context */
                oldOdds.current[runnerKey] = obj;
            }

            newRunnerObj[runnerKey] = {liveOdds:bhav,data:runnerData,isUpdated:isUpdated};
        }
        return newRunnerObj;
    }


    const makeMatchDate = (date) =>{
        try{
            if(date) {
                let d = moment.utc(date);
                return d.format('DD MMM HH:mm');
            } else {
                return '';
            }
        } catch(err){
            console.log(err)
        }
        
    }


    return (
        <div>
            <Header/>
            <div className='col-md-12 row'>
            <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainerDetalPage'>
                    <div id="dashboard-right-content">
                        <div className="dashborad-content-box matchbatContainer">
                            <div className='batOddContainer'>
                                <div className="tableTitle-box mobMatchBat-grid" style={{flexDirection:'row',marginBottom:'5px',display:'flex',justifyContent:'space-between'}}>
                                    <h4>{matchData?.match_name} -<span className='blockMobile'> {matchData?.series_name}</span></h4>
                                    <div style={{display:'flex'}}>
                                        <img onClick={()=>{
                                            setshowTv(!showTv);
                                        }} src="images/tv.png" alt="" style={{height:'25px',width:'25px'}}/>
                                        <h4>{makeMatchDate(matchData?.openDate,matchData?.timezone)}</h4>
                                    </div>
                                </div>
                                {(showTv)&&
                                    <RenderTv match_id={matchData?.match_id} />
                                }
                                <ScoreCard sport_id={matchData?.sport_id} match_id={matchData?.match_id} />
                                <OddBat heading={'Match Odds'} bat_format="normal" series_id={matchData?.series_id} match_id={matchData?.match_id} marketOdds={marketOdds?.odds} />
                                <OddBat heading={'Bookmaker'} bat_format="bookmaker" series_id={matchData?.series_id} match_id={matchData?.match_id} marketOdds={marketOdds?.bookmaker} />
                                <FansyBat series_id={matchData?.series_id} match_id={matchData?.match_id} marketOdds={marketOdds?.fansy}/>
                            </div>
                            <CurrentBatHistory type={'short'} match_id={matchData?.match_id} />
                        </div>
                    </div>
            </div>
            </div>
        </div>
    )
}



export default MatchBat
