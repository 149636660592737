import React, { useState, useEffect } from 'react'
import Leftpanel from '../../../includes/leftpanel';
import CustomerHeader from '../../../includes/customerHeader';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad, faL } from '@fortawesome/free-solid-svg-icons';
import { MatchList } from './../matchComponent/MatchList';
import Global from 'config/global';
import Header from 'component/web/includes/header';
import Loader from 'component/loader/loader';
import Slider from 'react-slick';
import { checkisMobile } from 'config/helper';
import { GET } from 'config/api';


function DashBord(props) {
    const [activeMenu,setActiveMenu] = useState(1);
    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);
  
    const [state, setState] = useState({
        isLoading:false,
        headerBanner:[],
        activeEvents:[]
      });
      
    const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
    }

    useEffect(() => {
		fetchHomeSLider();
        fetchActiveEvents();
	}, [],);

	const fetchHomeSLider = async () => {
		GET(`${Global.W_BASEURL}front/home/fetchHeaderData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				stateHandler("headerBanner", data.banner);
			}
		})
	}

    const fetchActiveEvents = async () => {
		GET(`${Global.W_BASEURL}customer/match/fetchActiveEvents`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
                stateHandler('activeEvents',data);
			}
		})
	}
    
	const settings_header_slider = {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
    const settings_header_minislider = {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 1000,
		slidesToShow: (checkisMobile()?2:8),
		slidesToScroll: 1,
	};

    return (
        // <div className="site-dashborad-section" >
        //    <Leftpanel /> 
        //     <div id="dashboard-right-content">
        //     <CustomerHeader /> 
        //         <div className="dashborad-content-box">
        //         <div className="dashboard-item-listing">
        //                 <MatchList title={'In-Play'} isactive={true} type={1}/>
        //                 {/* <MatchList title={'Cricket-Event'} type={2}/> */}
                        
        //             </div>
        //             <div className="dashboard-item-listing">
        //                 <MatchList title={'Upcoming-Event'} isactive={false} type={3}/>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div>
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                    <Slider className="homeBannerSlider" {...settings_header_slider}>
                        {state.headerBanner.map((value) => (
                            <div className="banner-slide">
                                <figure><img src={`${Global.IMAGE_PATH}${value}`} alt="Banner" /></figure>
                            </div>
                        ))}
                    </Slider>
                    <div className="homeBetting-livebox" style={{marginTop:'10px'}}>
                        {state?.activeEvents?.map((v,i)=>(
                            <MatchList value={v}/>
                        ))}
                        <div className="pageCustom-container" style={{padding:'0px'}}>
                            <div className="tableTitle-box">
                                <h4>Horse Racing</h4>
                            </div>
                                <Slider className='horsedatahome' {...settings_header_minislider}>
                                {[1,1,1,1,1,1,1,1,1].map((v,i) => (
                                    <li>
                                        <div className="horseData-box">
                                            <a className="btneffect">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <img src="https://images.staticcontent.io/rde/login/horse-login.svg" className="horsehomeimg" />
                                                    </div>
                                                    <div className="col">
                                                        <div className="d-inline-block text-center horsename-title"><div>Tarbes (FRA) 23rd Sep</div></div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                ))}
                                </Slider>
                        </div>
                        <div className="pageCustom-container" style={{padding:'0px'}}>
                            <div className="tableTitle-box">
                                <h4>Greyhound Racing</h4>
                            </div>
                                <Slider className='horsedatahome' {...settings_header_minislider}>
                                {[1,1,1,1,1,1,1,1,1].map((v,i) => (
                                    <li>
                                        <div className="horseData-box">
                                            <a className="btneffect">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <img src="https://images.staticcontent.io/rde/login/horse-login.svg" className="horsehomeimg" />
                                                    </div>
                                                    <div className="col">
                                                        <div className="d-inline-block text-center horsename-title"><div>Tarbes (FRA) 23rd Sep</div></div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                ))}
                                </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default DashBord;