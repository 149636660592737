import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import global from 'config/global';

const tvEqual = (prevProps, nextProps) =>{
    const { url } = nextProps;
    const { prevurl  } = prevProps;
    return (url == prevurl);
}

const RenderTv = memo(({match_id}) => {
    if(match_id) {
        return (
            <div className='liveTv-show'>
                <iframe src={`${global.LIVETV}?match_id=${match_id}`} >
                </iframe>
            </div>
        )
    } else {
        return null;
    }
});

export default RenderTv;
