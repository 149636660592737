import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import Axios from 'axios';
import Global from '../../../config/global';



function Footer(props) {
	
	const settings_footer_slider = {
		dots: true,
		infinite: true,
		autoplay: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
	  };

	  const [state, setState] = useState({
		footerBanner: ["footer_banner1.jpg"],
	});

	useEffect(() => {
		fetchFooterData();
	}, [],);

	const fetchFooterData = async () => {

		const response = await Axios.get(`${Global.W_BASEURL}front/home/fetchFooterData`).then((res) => {
			if (res.data.status == 200) {
				let data = res.data.data;
				stateHandler("footerBanner", data.banner);

			}
		})
	}

	const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}


	return (
		<>
		<div className="homeFooter-section">
			<div className="wide-container">
				<ul className="footLogo-section">
					<li><div className="footLogo-wrapper"><img src="images/footLogo1.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo2.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo3.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo4.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo5.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo6.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo7.png" alt="Amit Design" /></div></li>
					<li><div className="footLogo-wrapper"><img src="images/footLogo8.png" alt="Amit Design" /></div></li>
				</ul>
				<div className="homeCopyright-text">
					<p>You must be over 18 years old, or the legal age at which gambling or gaming activities are allowed under the law or jurisdiction that applies to you. You must reside in a country in which access to online gambling to its residents.</p>
				</div>
			</div>
		</div>
		</>
	);

}

export default Footer;
