import React, { useState } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import global from '../../../../config/global';

const Popup = ({ onClose }) => {
    const [fullscreenXlModal, setFullscreenXlModal] = useState(true);
    const [state, setState] = useState({
        headerLogo: 'logo.png'
    });

    return (
        <>
            <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog size=''>
                    <div className="modal-content">
                        <div className="modal-body-padding">
                            <div className="introModal-section">
                                <button type="button" className="close" data-bs-dismiss="modal" onClick={onClose}>×</button>
                                <img className="img-fluid" src={`${global.IMAGE_PATH}poster.jpg`} />
                            </div>
                        </div>
                    </div>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
};

export default Popup;