import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'
import { Nav, Navbar } from 'react-bootstrap';

import { useSelector,useDispatch } from 'react-redux';
import { Link, } from 'react-router-dom'
import {faBars } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

function NavBarPanel(props) {
	library.add(faBars);

	return (
		<div className="headerBottom">
			<ul className="newNav-scroll">
				<li><a href="#">Home</a></li>
				<li><a href="#">In-Play</a></li>
				<li><a href="#">Cricket</a></li>
				<li><a href="#">Soccer</a></li>
				<li><a href="#">Tennis</a></li>
				<li><a href="#">Horse</a></li>
				<li><a href="#">Greyhound</a></li>
				<li><a href="#">Indian Poker</a></li>
				<li><a href="#">Indian Poker II</a></li>
				<li><a href="#">Aviator</a></li>
				<li><a href="#">AE SEXY</a></li>
				<li><a href="#">Evolution</a></li>
				<li><a href="#">Live Casino</a></li>
				<li><a href="#">Vivo</a></li>
				<li><a href="#">Betgames</a></li>
				<li><a href="#">Casino III</a></li>
			</ul>
		</div>
		// <Navbar collapseOnSelect expand="lg" style={{padding:'0px',backgroundColor:'#045662'}}>
		// 	<Navbar.Brand href=""></Navbar.Brand>
		// 	<Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" >
		// 		<FontAwesomeIcon size='lg' style={{padding:'5px'}} color='white' icon={faBars} />
		// 	</Navbar.Toggle>
		// 	<Navbar.Collapse id="responsive-navbar-nav">
		// 		<Nav className='main-menu' >
		// 			<Nav.Link as={Link} className='navbarText' to="/home">Home</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">In-Play</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Cricket</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Soccer</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Tennis</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Horse</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Greyhound</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Indian Poker</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Indian Poker II</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Aviator</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">AE SEXY</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Evolution</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Live Casino</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Vivo</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Betgames</Nav.Link>
		// 			<Nav.Link as={Link} className='navbarText' to="#deets">Casino III</Nav.Link>
		// 		</Nav>
		// 	</Navbar.Collapse>
		// </Navbar>
	);

}


export default NavBarPanel;
