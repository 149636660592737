import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';
import Global from '../../../../config/global';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { POST,GET } from 'config/api';
import Loader from '../../../loader/loader';
import Header from 'component/web/includes/header';


function Terms(props) {

    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);

    const [state, setState] = useState({
        isLoading: false,
        title:'',
        matter:''
    });
    useEffect(() => {
        customerRuleData();
      }, [],);

      const customerRuleData = async () => {
        stateHandler("isLoading", true);

        const response = await GET(`${Global.W_BASEURL}customer/auth/fetchCustomerCmsData/9`).then((res)=>{
          if (res.data.status == 200) {
            let data = res.data.data;
                 stateHandler("title",data[0].title);
                 stateHandler("matter",data[0].matter);
          }
        })
        stateHandler("isLoading", false);

      }
      const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
      }
    return (
        <>
        {/* <Loader isLoading={state.isLoading} /> */}
        <div>
            <Header/>
              <div className='col-md-12 row'>
              <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                  <div className="dashborad-content-box">
                      <div className="category-title">
                          <h2><span>{state?.title}</span></h2>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: state?.matter }} />
                  </div>
              </div>
          </div>
        </div>
        </>
    );


}
export default Terms;