import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MatchList } from './matchComponent/MatchList';


function MultiMarker(props) {
    const [activeMenu,setActiveMenu] = useState();
    library.add(faTv,faFlag,faBars,faStar,faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);

    return (
        <div className="site-dashborad-section" >
           <Leftpanel /> 
            <div id="dashboard-right-content">
            <CustomerHeader /> 
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2><span>MultiMarker</span></h2>
                    </div>
                    <div className="dashboard-item-listing">
                        <MatchList title={'In Play'} type={1} />
                    </div>
                </div>
            </div>
        </div>
    );

}
export default MultiMarker;