import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import Accordion from 'react-bootstrap/Accordion';
import { POST } from 'config/api';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCircle } from '@fortawesome/free-solid-svg-icons';
import global from 'config/global';
import moment from 'moment';
import Header from 'component/web/includes/header';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function Withdrawal(props) {
    library.add(faCircle);
    const [AccountList,setAccountList] = useState([]);
    const [withdrawalHistory,setwithdrawalHistory] = useState([]);
    const {buyerdata} = useSelector(state => state?.buyerAuth);

    const [state,setState] = useState({
        accountname:'',
        accountnumber:'',
        ifsccode:'',
        mobile:'',
        password:'',
        upi_id:'',
        upi_platform:'',
        account_type:'bank',
        withdrawal_amount:'',
        account_id:''
    });
    
    useEffect(()=>{
        getBankAccountList();
        getWithdrawalHistory();
    },[])
	
    const stateHandler = (key, value) => {
		state[key] = value;
		setState({ ...state });
	}

    const getBankAccountList = () => {
        let body = {
            index:0,
            limit:10,
        }

        POST(`${global.W_BASEURL}customer/transaction/getBankAccountList`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setAccountList(data)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const getWithdrawalHistory = () => {
        let body = {
            index:0,
            limit:10,
        }

        POST(`${global.W_BASEURL}customer/transaction/getWithdrawalHistory`,body).then((res)=>{
            if(res.data.status == 200) {
                let data = res.data?.data;
                setwithdrawalHistory(data)
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    

    const withdrawRequest = () => {
        let body = {
            withdrawal_amount:state?.withdrawal_amount,
            bank_account_id:state?.account_id,
            parent_id:buyerdata?.parent_id,
        }
        
        
        POST(`${global.W_BASEURL}customer/transaction/withdrawRequest`,body).then((res)=>{
            if(res.data.status == 200) {
                emptyState();
                toast("Withdrawal Request Submited");
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    const emptyState = () =>{
        setState({
            accountname:'',
            accountnumber:'',
            ifsccode:'',
            mobile:'',
            password:'',
            upi_id:'',
            upi_platform:'',
            account_type:'bank',
            withdrawal_amount:'',
            account_id:''
        })
    }
    const addBankAccount = () => {
        let body = {
            account_name:state?.accountname,
            account_number:state?.accountnumber,
            ifsc_code:state?.ifsccode,
            mobile:state?.mobile,
            password:state?.password,
            account_type:state?.account_type,
            upi_platform:state?.upi_platform,
            upi_id:state?.upi_id,
        }
        
        POST(`${global.W_BASEURL}customer/transaction/addBankAccount`,body).then((res)=>{
            if(res.data.status == 200) {
                emptyState();
                toast("Bank Successfully Added");
            }
        }).catch((err)=>{
            console.log(err,'/////');
        })
    }

    return (
        <div>
            <Header/>
            <div className='col-md-12 row'>
                <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item>
                            <Accordion.Header>Add Account</Accordion.Header>
                            <Accordion.Body>
                            <div className='row'>
                                    <div className='col-lg-12'>
                                        <div style={{marginRight:'10px'}}>
                                            <label for="bank" style={{marginRight:'5px'}}>Bank Account</label>
                                            <input checked={(state?.account_type == 'bank')} id={'bank'} type="radio" style={{color:'#000000',marginBottom:'4px'}} name='account_type' onChange={({target}) => {
                                                stateHandler('account_type','bank')
                                            }} />
                                        </div>
                                        <div style={{marginRight:'10px'}}>
                                            <label for="upi" style={{marginRight:'5px'}}>UPI</label>
                                            <input checked={(state?.account_type == 'upi')} id={'upi'} type="radio" style={{color:'#000000',marginBottom:'4px'}} name='account_type' onChange={({target}) => {
                                                stateHandler('account_type','upi')
                                            }} />
                                        </div>
                                    </div>


                                    {/* {(state?.account_type == 'bank')? */}
                                        {/* <div> */}
                                            <div className='col-lg-4'>
                                                <label >Account Name</label>
                                                <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Account Name" onChange={({target}) => {
                                                    stateHandler('accountname',target?.value)
                                                }} value={state?.accountname} />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label >Account Number</label>
                                                <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Account Number" onChange={({target}) => { 
                                                    const re = /^[0-9\b]+$/;
                                                    if (target.value === '' || re.test(target.value)) {
                                                    stateHandler('accountnumber',target.value);
                                                    }
                                                }} value={state.accountnumber} />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label >IFSC Code</label>
                                                <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter IFSC Code" onChange={({target}) => { 
                                                    stateHandler('ifsccode',target?.value)
                                                }} value={state?.ifsccode}/>  
                                            </div>
                                        
                                    {/* : */}
                                        {/* <div> */}
                                        <div className='col-lg-4'>
                                        <label >UPI Id</label>
                                        <input type="text" value={state?.upi_id} style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter UPI ID" onChange={({target}) => {
                                                stateHandler('upi_id',target?.value)
                                            }} />
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="form-group">
                                                <label >Select Upi Platform</label>
                                                <div className="NiceSelect">
                                                    <select value={state?.upi_platform} style={{color:'#000000'}} className="form-control" onChange={({target})=>{
                                                        stateHandler('upi_platform',target?.value)
                                                    }}>
                                                        <option value={''}>Select Platform</option>
                                                        <option value={'googlepay'}>Google Pay</option>
                                                        <option value={'phonepay'}>Phone Pay</option>
                                                        <option value={'paytm'}>Paytm</option>
                                                        <option value={'payzap'}>Payzap</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-lg-4'>
                                    
                                    <label >Mobile Number</label>
                                    <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Mobile Number" onChange={({target}) => {
                                        const re = /^[0-9\b]+$/;
                                        if (target.value === '' || re.test(target.value)) {
                                            stateHandler('mobile',target?.value)
                                        }
                                    }} value={state.mobile} />  
                                    </div>
                                    <div className='col-lg-4'>
                                    <label >Password</label>
                                    <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Password" onChange={({target}) => { 
                                        stateHandler('password',target?.value)
                                    }} value={state.password} />  
                                    </div>
                                    <div className='col-lg-12'>
                                    <button onClick={()=>{
                                        addBankAccount()
                                    }} className='btn btn-success'>Submit</button>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item>
                            <Accordion.Header>Add Account</Accordion.Header>
                            <Accordion.Body>
                                <div className='row'>
                                    {AccountList?.map((item,index)=>(
                                        (item?.account_type == `bank`)?
                                            <div className='col-lg-3 col-md-4' onClick={()=>{
                                                stateHandler('account_id',item?.id)
                                            }} >
                                                <div className='boxAc-viewData'>
                                                    <div>Account Name : {item?.account_name}</div>
                                                    <div>Account Number : {item?.account_number}</div>
                                                    <div>Account IFSC Code : {item?.ifsc_code}</div>
                                                    <div>Account Mobile : {item?.mobile}</div>
                                                </div>
                                            </div>
                                        :
                                        <div className='col-lg-3 col-md-4' onClick={()=>{
                                            stateHandler('account_id',item?.id)
                                        }}>
                                            <div className='boxAc-viewData'>
                                                <div>UPI ID : {item?.upi_id}</div>
                                                <div>Account Mobile : {item?.mobile}</div>
                                                <div>UPI Platform : {item?.upi_platform}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                        </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    {(state?.account_id)&&
                        <div>
                            <input type="text" style={{color:'#000000',marginBottom:'4px'}} className="form-control" placeholder="Enter Withdraw Amount" onChange={({target}) => { 
                                    const re = /^[0-9\b]+$/;
                                    if (target.value === '' || re.test(target.value)) {
                                        stateHandler('withdrawal_amount',target?.value)
                                    }
                                }} value={state.withdrawal_amount} />  
                            <button onClick={()=>{
                                withdrawRequest()
                                }} className='btn btn-success'>Submit</button>
                        </div>
                    }
                    
                    {(withdrawalHistory?.length > 0) &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Bank Account</th>
                                            <th>UPI</th>
                                            <th>Mobile</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {withdrawalHistory?.map((item,index)=>(
                                            <tr style={{textAlign:'center'}}>
                                                <th style={{'width':'50px'}}>{index + 1}</th>
                                                <td>{(item?.account_number)?item?.account_number:'--'}</td>
                                                <td>{(item?.upi_id)?item?.upi_id:'--'}</td>
                                                <td>{item?.mobile}</td>
                                                <td>{item?.amount}</td>
                                                <td >{item?.status}</td>
                                                <td>{moment(item?.created_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    );

}
export default Withdrawal;