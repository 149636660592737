import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { abbreviateNumber } from 'config/helper';
import { useSelector } from 'react-redux';


const memoCompare = (prevProps, nextProps) =>{
    if((prevProps?.value?.isUpdated != nextProps?.value?.isUpdated) || (prevProps?.forceRefresh != nextProps?.forceRefresh)) {
        return false;
    } else {
        return true;
    }
}

const RenderMatchItem = memo(({value,bat_format,onBetClick}) => {
    /** use when some time data are not available in back like 0 bhav item array not come in back array */
    const {exposureData} = useSelector(state => state.buyerGlobal);
    
    const onPress = (batItem,index,type) =>{
        let enableIndex = (type == 'back') ? 2:0
        if(!value.is_suspend && !value.is_ball_running && index === enableIndex)
        {
            let content = {
                runnerName:value.team_name,
                runner_id:value.sid,
                batRate:batItem.odds,
                battype:type,
                bat_format:bat_format, 
            }
            onBetClick(content)
        }
    }
    
    let exposureObj = (exposureData?.[bat_format])?exposureData[bat_format]?.exposure_obj:{};
    const checkExposureValue = (value) =>{
        let sid = value?.sid
        if(sid && exposureObj && exposureObj[sid]) {
            let exposure_value = exposureObj?.[sid];
            if(Math.sign(exposure_value) == -1) {
                return <span style={{marginLeft:'10px',color:'red'}}>{exposure_value}</span>
            } else {
                return <span style={{marginLeft:'10px',color:'green'}}>{exposure_value}</span>
            }

        } else {
            return '';
        }
    }
    const checkBatIsSuspended = () =>(value?.status == 'SUSPENDED' || value?.status == 'Ball Running');
    
/** use when some time data are not available in back like 0 bhav item array not come in back array */
    const getLayBackFullArray = (itemArray) =>{
        let remainingItem = 3 - itemArray?.length;
        let newArray = [...itemArray];

        if(remainingItem) {
            Array(remainingItem).fill().map((v, i) =>{
                newArray.push({
                    isUpdated: false,
                    odds: 0,
                    size: 0,
                })
            })
        }
        return newArray;
    }

    let back_odds = getLayBackFullArray(value?.back_odds);
    let lay_odds = getLayBackFullArray(value?.lay_odds);
    return (
        <div className='matchList-grid'>
            <div className='row align-items-center' style={{paddingTop:'3px'}}>
                <div className='col-7' style={{'cursor': 'pointer'}}>
                    <div className="tableFirst-col">
                        <div className="tableEvent-title">{value.team_name} {checkExposureValue(value)}</div>
                    </div>
                </div>
                
                <div className='col-5'>
                    <div className={`${(checkBatIsSuspended()) && 'btnOverlay1' }`}>
                        {(checkBatIsSuspended())&&
                            <div className='overlayText'>{value?.status}</div>
                        }
                        <div className='row gx-2'>
                            <div className='col-6'>
                                <div className="col-draw groupTable-price">
                                    <div className="subGroup-table">
                                        {back_odds?.map((item,index)=>{
                                            return (
                                                <a onClick={()=>{
                                                    if(item?.odds > 0) {
                                                        onPress(item,index,'back')
                                                    }
                                                }}  id="btnBack0" class={`btn-back back${index} ${(index != 2) && 'noneMobile-hide'} ${(item.isUpdated ) && 'colorBlinkBack'} `} style={{cursor:'context-menu'}} side="Lay">
                                                    <div style={{"min-height": "13px"}}>{item?.odds} <br /> <span style={{fontSize:'10px'}}>{abbreviateNumber(item?.size)}</span> </div>
                                                </a>
                                            )}
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="col-draw groupTable-price">
                                    <div className="subGroup-table">
                                    {lay_odds?.map((item,index)=>{
                                            return (
                                                <a onClick={()=>{
                                                    if(item?.odds > 0) {
                                                        onPress(item,index,'lay')
                                                    }
                                                }}  id="btnLay0" class={`btn-lay lay${index} ${(index != 0) && 'noneMobile-hide'} ${(item.isUpdated ) && 'colorBlinkBack'} `} style={{cursor:'context-menu'}} side="Lay">
                                                    <div style={{"min-height": "13px"}}>{item?.odds} <br /> <span style={{fontSize:'10px'}}>{abbreviateNumber(item?.size)}</span></div>
                                                </a>
                                            )}
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)},memoCompare);


export default RenderMatchItem;
