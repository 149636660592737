import React, { memo, useState } from 'react'
import {POST,GET} from 'config/api';
import global from 'config/global';
import {useDispatch,useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {toast } from 'react-toastify';
// import Loader from 'components/loader/loader';
import { updateExposure, updateUserWallet } from 'config/helper';
import { emit } from 'config/event';


const batModalEqual = (prevProps, nextProps) => {
    const { showBatModal,liveBhav } = nextProps;
    const { showBatModal: prevshowBatModal,liveBhav:prevliveBhav,modalItem  } = prevProps;

    /*if the props are equal, it won't update*/
    if(showBatModal && modalItem?.runner_id != undefined) {
        let runner_id = modalItem?.runner_id;
        const showBatModalEqual = (liveBhav[runner_id]?.back?.price == prevliveBhav[runner_id]?.back?.price || liveBhav[runner_id]?.lay?.price == prevliveBhav[runner_id]?.lay?.price) ;
        return showBatModalEqual;
        // return (showBatModal == prevshowBatModal);
    } else {
        return (showBatModal == prevshowBatModal);
    }
};
    

const RenderBatModal = memo(({showBatModal,batModalType='Modal',matchItem={},liveBhav={},modalItem={} ,onClose}) =>{
    const {buyerdata} = useSelector(state => state.buyerAuth);
    const {exposureData} = useSelector(state => state.buyerGlobal);
    const [delayLoader,setDelayLoader] = useState(false)
    
    
    let bhavback = liveBhav?.[modalItem?.runner_id]?.back;
    let bhavlay = liveBhav?.[modalItem?.runner_id]?.lay;
    
    if(modalItem?.fansy_id) {
        bhavback = liveBhav?.[modalItem?.fansy_id]?.back;
        bhavlay = liveBhav?.[modalItem?.fansy_id]?.lay;
    }

    const calculatePNL = (selectionId,bat_format) =>{
        let runner_id = selectionId;

        let rate = parseFloat(modalItem?.batRate);
        let price = parseFloat(stack);
        
        let bat_type = modalItem?.battype;
        // let exposure_item = (exposureData?.exposure_obj && Object.keys(exposureData?.exposure_obj)?.length)?exposureData?.exposure_obj:{};
        // let oldExposureValue = (exposure_item[runner_id])?parseFloat(exposure_item[runner_id]):0

        if(price) {

            if(runner_id == modalItem?.runner_id) {
                let profit = Math.round((rate * price) - price)
                
                if(bat_format == 'bookmaker') {
                    profit = Math.round((rate * price) / 100)
                } 


                if(bat_type == 'lay') {
                    // profit = -profit + oldExposureValue;
                    profit = -profit;
                    return profit.toFixed(2);
                } else {
                    // profit = profit + oldExposureValue;
                    profit = profit;
                    return profit.toFixed(2);
                }
            } else {
                if(bat_type == 'lay') {
                    // price = price + oldExposureValue;
                    price = price;
                    return price.toFixed(2);
                } else {
                    // price = price - oldExposureValue;
                    price = price;
                    return -price.toFixed(2);;
                }
            }
        } else {
            return 0;
        }
    }
    
    const batPlace = (v) =>{

        let liveBatRate = (v.battype == 'back')?bhavback:bhavlay
        
        if(v.batRate != liveBatRate?.odds) {
            onClose()
        }
        else if(!isNaN(parseFloat(v.stack)) && !isNaN(parseFloat(v.batRate))) {

            let body = {
                team_name:v.runnerName,
                team_id:v.runner_id, 
                bat_type:v.battype, 
                bat_rate:v.batRate, 
                bat_size:v.batSize,
                bat_format:v.bat_format, 
                bat_stack:v.stack,
                fansy_id:v.fansy_id, 
                match_id:v?.match_id,
                marketId:v?.marketId,
                series_id:v?.series_id,
                user_id:buyerdata.id,
                user_parent_id:buyerdata.parent_id,
                user_type:'customer',
                exposure_bal:0,
                exposure_obj:{}
            }
            
            if(v.bat_format == 'normal' || v.bat_format == 'bookmaker') {
                let exposure_bal = 0;
                let exposure_obj = {};
                let oldexposureData = (exposureData?.[v.bat_format])?exposureData?.[v.bat_format]:{};
                

                for(let item of modalItem?.runnerList) {
                    let selectionId = item.sid;
                    let value = parseFloat(calculatePNL(selectionId,v.bat_format));

                    if(value < 0) {
                        let expValue = Math.abs(value);
                        exposure_bal = (expValue > exposure_bal)?expValue:exposure_bal;
                    }
                    let oldVal = oldexposureData?.exposure_obj?.[selectionId];
                    oldVal = (oldVal)?oldVal:0;
                    exposure_obj[selectionId] = (value + oldVal);
                }
                
                body.exposure_bal = exposure_bal;
                body.exposure_obj = JSON.stringify(exposure_obj);
                body.exposure_id = oldexposureData?.exposure_id;
            }

            POST(`${global.W_BASEURL}customer/bat/batplace`,body).then((res) => {
                if(res.data.status == 200){
                    toast.info("Bat Placed Successfully",{autoClose: 2000});
                    updateUserWallet(res?.data?.data?.exposure_bal,'sub');
                    refreshExposure();
                    onClose('batPlaced')
                }else{
                    if(res.data.msg) {
                        toast.info(res.data.msg,{autoClose: 2000});
                        onClose()
                    }
                }
            }).catch((err)=>{
                console.log(err,'////////////');
                onClose()

            })
        } else {
            toast.info("Please Enter Stack",{autoClose: 2000});
        }
    }

    const refreshExposure = () =>{
        emit('refreshExposure',true);
        emit('refreshBatHistory',true);
    }

    const [stack,setstack] = useState(100);
    const stackArray = [
        100,250,500,750,1000,2000,3000,5000
    ]
    

    const placeBatDelay = (data) =>{
        let delayTime = (global.delayTime)?global.delayTime:3000;
        setDelayLoader(true);

        setTimeout(()=>{
            setDelayLoader(false);
            batPlace(data)
            setstack('');
        }, delayTime);
    }
    if(batModalType == 'Modal') {
        return (
            <Modal show={showBatModal} backdrop="static" dialogClassName="batModal">
                {/* <Loader  isLoading={isLoading} /> */}
                <div className="modal-content mobileWidth" style={{width:'100%'}}>
                    <div className="modal-header" style={{padding:'0px',marginTop:'px'}}>
                        <div className="multi-counter-box">
                            <div className="row">
                                <div className="col">
                                    <h6>{modalItem?.runnerName}</h6>
                                    <h6>{(modalItem?.battype == 'back')?'Back':'Lay'} (Bet For)</h6>
                                </div>
                                {(modalItem?.bat_format == 'fansy')?
                                    <div className='col-auto' style={{display:'flex',flexDirection:'row'}}>
                                        <div className="col-auto" style={{marginRight:'10px'}}>
                                            <div className="counter-number" style={{backgroundColor:'#f9bfcc',borderRadius:'5px',height:'50px'}}>
                                                <h4>{bhavlay?.odds} <strong>{bhavlay?.size}</strong></h4>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="counter-number" style={{borderRadius:'5px',height:'50px'}}>
                                                <h4>{bhavback?.odds} <strong>{bhavback?.size}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className='col-auto' style={{display:'flex',flexDirection:'row'}}>
                                        <div className="col-auto" style={{marginRight:'10px'}}>
                                            <div className="counter-number" style={{borderRadius:'5px',height:'50px'}}>
                                                <h4>{bhavback?.odds} <strong>{bhavback?.size}</strong></h4>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="counter-number" style={{backgroundColor:'#f9bfcc',borderRadius:'5px',height:'50px'}}>
                                                <h4>{bhavlay?.odds} <strong>{bhavlay?.size}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                    
                    <div className="modal-body" style={{backgroundColor:(modalItem?.battype == 'back')?'rgb(117, 194, 253,0.6)':'rgb(246, 148, 170,0.6)'}}>
                        <div className="beatModalBox-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rate</label>
                                        <input readOnly value={modalItem?.batRate} disabled type="text" className="form-control batInput" placeholder="Enter Rate" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Stake</label>
                                        <input value={stack} onChange={({target})=>{
                                            const re = /^[0-9\b]+$/;
                                            if (target.value === '' || re.test(target.value)) {
                                                setstack(target.value)
                                            }
                                        }} type="text" className="form-control batInput" placeholder="Enter Stake" />
                                    </div>
                                </div>
                            </div>
                            <div className="quick-bet-box" style={{marginTop:'10px'}}>
                                {stackArray?.map((v,i)=>(
                                    <div onClick={()=>{
                                        let value = (stack)?parseFloat(stack)+v:v
                                        setstack(value)
                                    }} className='btn batStackButton'>
                                        {v}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {(delayLoader)?
                    <div className="modal-footer spinerbg" style={{justifyContent:'center'}}>
                        <div className="">
                                Please Wait
                                <img src='/images/spin.gif' style={{height:'20px',width:'20px'}} />
                        </div>
                    </div> 
                    :
                    <>
                        <div className="modal-footer">
                            <div className="">
                                <div className="row justify-content-center">
                                    <div className="col-auto" onClick={()=>{
                                        onClose(false)
                                        setstack('')
                                    }}>
                                        <div className="link-btn link-btn1">
                                            <a href="javascript:void(0);" data-bs-dismiss="modal" style={{color:'#EBB553 !important'}}>Cancel</a>
                                        </div>
                                    </div>
                                    <div onClick={()=>{
                                            placeBatDelay({stack,...modalItem})
                                        }} className="col-auto">
                                        <div className="link-btn place-betBtn">
                                            <a href="javascript:void(0);">Place Bet</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    <div>
                        {modalItem?.runnerList?.map((value)=>(
                            <div style={{display:'flex',paddingLeft:'15px',paddingRight:'15px',justifyContent:'space-between','borderBottom': '1px solid #cfcfcf','paddingTop': '10px'}} >
                                <span style={{color:'#000'}}>{value.team_name}</span>
                                <span style={{color:'#000'}}><span style={{color:(calculatePNL(value?.sid,modalItem?.bat_format) < 0)?'red':'green'}}>{calculatePNL(value?.sid,modalItem?.bat_format)}</span> </span>
                            </div>
                        ))}
                    </div>  
                    </>
                    }
                </div>
            </Modal>
        )
    } else {
        return (
            <div className="w-100">
                {(delayLoader)&&
                    <div className="modal-footer spinerbg2" style={{width:'100%'}}>
                        <div className="" style={{color:'#000',fontSize:'18px'}}>
                                Please Wait
                                <img src='/images/spin.gif' style={{height:'20px',width:'20px'}} />
                        </div>
                    </div> 
                }
                <div id="" className="back-bg-dark">
                    <div colspan="11" className="w-100" style={{justifyContent: 'end',display: 'flex'}}>
                        <div className="bt-title float-right" style={{ "marginRight": "0px"}}><span className="bt-slip-title font-weight-bold " style={{ "marginLeft": "5px;"}}>{modalItem?.runnerName}</span><span className="bt-slip-title-light  font-weight-bold">- MATCH ODDS</span>
                            <div className="input_group_btn betslip-group mr-1">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-plus-minus minusborder btncolorpm" data-type="minus">
                                        <span className="fa fa-minus"></span>
                                    </button>
                                </span>
                                <input type="text" value={modalItem?.batRate} style={{ color:'#000000',"appearance": "textfield"}} step="0.01" className="form-control font-weight-bold text-center bet-amt" min="0" id="test"  />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-plus-minus plusborder btncolorpm" data-type="plus">
                                        <span className="fa fa-plus"></span>
                                    </button>
                                </span>
                            </div>
                            <div className="input_group_btn betslip-group">
                                <span className="input-group-btn">
                                    <button onClick={()=>{
                                        let value = (stack && stack > 0)?parseFloat(stack)-100:0
                                        setstack(value)
                                    }} type="button" className="btn btn-plus-minus minusborder btncolorpm" data-type="minus" data-field="quant[2]">
                                        <span className="fa fa-minus"></span>
                                    </button>
                                </span>
                                <input type="number" value={stack} style={{ color:'#000000',"appearance": "textfield"}} className="form-control font-weight-bold text-center bet-amt" min="0"  id="test" />
                                <span className="input-group-btn">
                                    <button onClick={()=>{
                                        let value = (stack && stack > 0)?parseFloat(stack)+100:100
                                        
                                        setstack(value)
                                    }} type="button" className="btn btn-plus-minus plusborder btncolorpm" data-type="plus" data-field="quant[2]">
                                        <span className="fa fa-plus"></span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="br-top back-bg-light placebet-hide-mobile b-t-slip">
                    <div colspan="11" className="w-100" style={{display: 'flex',justifyContent: 'end'}}>
                        <div className="bt-title float-right " style={{ "marginRight": "5px",justifyContent: 'end'}}>
                            <div className="bt-title d-inline">
                                {stackArray?.map((v,i)=>(
                                    <span onClick={()=>{
                                        let value = (stack)?parseFloat(stack)+v:v
                                        setstack(value)
                                    }} className="btn-place-bet btn-bid">{v}</span>
                                ))}
                            </div><span className="btn-place-bet btn-green" onClick={()=>{
                                placeBatDelay({stack,...modalItem})
                            }}>Place Bet</span><span onClick={()=>{
                                onClose(false)
                                setstack('')
                            }} className="btn-cancel btn-place-cancel">Cancel</span></div>
                    </div>
                </div>
            </div>
        );
    }
});
    export default RenderBatModal;