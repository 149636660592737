import React, { useState, useEffect } from 'react'
import Leftpanel from '../../includes/leftpanel';
import CustomerHeader from '../../includes/customerHeader';
import Axios from 'axios';
import Global from '../../../../config/global';
import { useSelector } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../loader/loader';
import { POST,GET } from 'config/api';
import Header from 'component/web/includes/header';


function Stackes(props) {
	const buyerdata = useSelector((state) => state.buyerAuth.buyerdata);
    library.add(faTv, faFlag, faBars, faStar, faBarsStaggered, faHouse, faStarOfLife, faChartSimple, faBook, faTicket, faBookBookmark, faPenToSquare, faAddressCard, faUnlockKeyhole, faRightFromBracket);    
    
    const [state, setState] = useState({
        isLoading: false,
        stackLable1:'',
        stackLableVal1:'',
        stackLable2:'',
        stackLableVal2:'',
        stackLable3:'',
        stackLableVal3:'',
        stackLable4:'',
        stackLableVal4:'',
        stackLable5:'',
        stackLableVal5:'',
        stackLable6:'',
        stackLableVal6:'',
        stackLable7:'',
        stackLableVal7:'',
        stackLable8:'',
        stackLableVal8:'',
      });

      const [errorState, setErrorState] = useState({
        errorStackLable1:'',
        errorStackLableVal1:'',
        errorStackLable2:'',
        errorStackLableVal2:'',
        errorStackLable3:'',
        errorStackLableVal3:'',
        errorStackLable4:'',
        errorStackLableVal4:'',
        errorStackLable5:'',
        errorStackLableVal5:'',
        errorStackLable6:'',
        errorStackLableVal6:'',
        errorStackLable7:'',
        errorStackLableVal7:'',
        errorStackLable8:'',
        errorStackLableVal8:'',
      });

      useEffect(() => {
        customerStackData();
      }, [],);
      const customerStackData = async () => {
        stateHandler("isLoading", true);

        const response = await GET(`${Global.W_BASEURL}customer/match/fetchCustomerStackesData/${buyerdata.id}`).then((res)=>{
          if (res.data.status == 200) {
            stateHandler("isLoading", false);
            let data = res.data.data;
                // console.log('stackes ',data)
                stateHandler("stackLable1",data.stackes.stack_lable_1);
                stateHandler("stackLableVal1",data.stackes.stack_lable_val_1);
                stateHandler("stackLable2",data.stackes.stack_lable_2);
                stateHandler("stackLableVal2",data.stackes.stack_lable_val_2);
                stateHandler("stackLable3",data.stackes.stack_lable_3);
                stateHandler("stackLableVal3",data.stackes.stack_lable_val_3);
                stateHandler("stackLable4",data.stackes.stack_lable_4);
                stateHandler("stackLableVal4",data.stackes.stack_lable_val_4);
                stateHandler("stackLable5",data.stackes.stack_lable_5);
                stateHandler("stackLableVal5",data.stackes.stack_lable_val_5);
                stateHandler("stackLable6",data.stackes.stack_lable_6);
                stateHandler("stackLableVal6",data.stackes.stack_lable_val_6);
                stateHandler("stackLable7",data.stackes.stack_lable_7);
                stateHandler("stackLableVal7",data.stackes.stack_lable_val_7);
                stateHandler("stackLable8",data.stackes.stack_lable_8);
                stateHandler("stackLableVal8",data.stackes.stack_lable_val_8);
          }
        })
      }

      const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
      }
      const errorStateHandler = (key, value) => {
        errorState[key] = value;
        setErrorState({ ...errorState });
      }
      const saveStackes = () => {
        if(validation()){
            stateHandler("isLoading", true);
            let body = {
                userid:buyerdata.id,
                stack_lable_1:state.stackLable1,
                stack_lable_val_1:state.stackLableVal1,
                stack_lable_2:state.stackLable2,
                stack_lable_val_2:state.stackLableVal2,
                stack_lable_3:state.stackLable3,
                stack_lable_val_3:state.stackLableVal3,
                stack_lable_4:state.stackLable4,
                stack_lable_val_4:state.stackLableVal4,
                stack_lable_5:state.stackLable5,
                stack_lable_val_5:state.stackLableVal5,
                stack_lable_6:state.stackLable6,
                stack_lable_val_6:state.stackLableVal6,
                stack_lable_7:state.stackLable7,
                stack_lable_val_7:state.stackLableVal7,
                stack_lable_8:state.stackLable8,
                stack_lable_val_8:state.stackLableVal8,
                
              };
              POST(Global.W_BASEURL+'customer/match/editStackes',body).then((res)=>{
                stateHandler("isLoading", false);
                    if(res.data.status==200)
                    {
                        toast.success("Request Updated Successfully",{autoClose: 2000});
                    }
                }).catch((err)=>{
                    console.log(err);
                });
        }
      }
      const validation = () => {
        // console.log('yyyy',errorState.errorStackLable1);
        let error = {
        'errorStackLable1':'',
        'errorStackLableVal1':'',
        'errorStackLable2':'',
        'errorStackLableVal2':'',
        'errorStackLable3':'',
        'errorStackLableVal3':'',
        'errorStackLable4':'',
        'errorStackLableVal4':'',
        'errorStackLable5':'',
        'errorStackLableVal5':'',
        'errorStackLable6':'',
        'errorStackLableVal6':'',
        'errorStackLable7':'',
        'errorStackLableVal7':'',
        'errorStackLable8':'',
        'errorStackLableVal8':'',
        }
        setErrorState(error);
        //  console.log('xxx',errorState);
        if (state.stackLable1 == '') {
            errorStateHandler('errorStackLable1','Please Enter Stack Lable 1');
            return false;
          }
          else if (state.stackLableVal1 == '') {
            errorStateHandler('errorStackLableVal1','Please Enter Stack Value 1');
            return false;
          }
          else {
            return true;
          }


      }

    return (
        <>
        {/* <Loader isLoading={state.isLoading} /> */}
        <div>
            <Header/>
            <div className='col-md-12 row'>
            <div className='leftPanelMenu' >
                    <Leftpanel /> 
                </div>
                <div className='rightBoxContainer' >
                <div className="dashborad-content-box">
                    <div className="category-title">
                        <h2>Edit <span>Stakes</span></h2>
                    </div>
                    <div className="profit-loss-filter">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 1</label>
                                    <input type="text" className="form-control" value={state.stackLable1} placeholder="Stake Label 1" onChange={(event) => { stateHandler('stackLable1',event.target.value) }} />
                                    <span style={{ 'color': 'red' }}>{errorState.errorStackLable1}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 1</label>
                                    <input type="number" className="form-control" value={state.stackLableVal1} placeholder="Stake Value 1" onChange={(event) => { stateHandler('stackLableVal1',event.target.value) }} />
                                    <span style={{ 'color': 'red' }}>{errorState.errorStackLableVal1}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 2</label>
                                    <input type="text" className="form-control" value={state.stackLable2} placeholder="Stake Label 2" onChange={(event) => { stateHandler('stackLable2',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 2</label>
                                    <input type="number" className="form-control" value={state.stackLableVal2} placeholder="Stake Value 2" onChange={(event) => { stateHandler('stackLableVal2',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 3</label>
                                    <input type="text" className="form-control" value={state.stackLable3} placeholder="Stake Label 3" onChange={(event) => { stateHandler('stackLable3',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 3</label>
                                    <input type="number" className="form-control" value={state.stackLableVal3} placeholder="Stake Value 3" onChange={(event) => { stateHandler('stackLableVal3',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 4</label>
                                    <input type="text" className="form-control" value={state.stackLable4} placeholder="Stake Labe 4" onChange={(event) => { stateHandler('stackLable4',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 4</label>
                                    <input type="number" className="form-control" value={state.stackLableVal4} placeholder="Stake Value 4" onChange={(event) => { stateHandler('stackLableVal4',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 5</label>
                                    <input type="text" className="form-control" value={state.stackLable5} placeholder="Stake Label 5" onChange={(event) => { stateHandler('stackLable5',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 5</label>
                                    <input type="number" className="form-control" value={state.stackLableVal5} placeholder="Stake Value 5" onChange={(event) => { stateHandler('stackLableVal5',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 6</label>
                                    <input type="text" className="form-control" value={state.stackLable6} placeholder="Stake Label 6" onChange={(event) => { stateHandler('stackLable6',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 6</label>
                                    <input type="number" className="form-control" value={state.stackLableVal6} placeholder="Stake Value 6" onChange={(event) => { stateHandler('stackLableVal6',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 7</label>
                                    <input type="text" className="form-control" value={state.stackLable7} placeholder="Stake Label 7" onChange={(event) => { stateHandler('stackLable7',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 7</label>
                                    <input type="number" className="form-control" value={state.stackLableVal7} placeholder="Stake Value 7" onChange={(event) => { stateHandler('stackLableVal7',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Label 8</label>
                                    <input type="text" className="form-control" value={state.stackLable8} placeholder="Stake Label 8" onChange={(event) => { stateHandler('stackLable8',event.target.value) }} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label>Stake Value 8</label>
                                    <input type="number" className="form-control" value={state.stackLableVal8} placeholder="Stake Value 8" onChange={(event) => { stateHandler('stackLableVal8',event.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className="link-btn">
                            <a href="javascript:void(0);" onClick={() => { saveStackes() }}><i className="fa-solid fa-floppy-disk mr10" /> Save</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
        
    );

}
export default Stackes;