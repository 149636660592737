let live = true;
let STATIC_IMAGE_PATH = 'https://localhost:3002/images/site_images/';
let IMAGE_PATH = 'https://localhost/crickbuzz/images/';
let LOGO_PATH = 'https://localhost/crickbuzz/images/logo/';
let A_BASEURL = 'http://localhost:3000/admin/v-1.0.0/';
let W_BASEURL = 'http://localhost:3000/web/v-1.0.0/';
let MatchAPI = 'https://backend2.vasudevexchange.com/admin/v-1.0.0/';
let ENCRYPTION_KEY = 'vasudevexchange'
let delayTime = 3000;
let SCORECARD = 'https://dpmatka.in/dcasino/score.php';
// let LIVETV = 'https://dpmatka.in/dcasino/nntv.php';
let LIVETV = 'https://iframe.cricketchampion.online/tv-cricket/live.php';
let SITEURL = 'https://www.vasudevexchange.com';

if(live)
{
    STATIC_IMAGE_PATH = 'https://img.vasudevexchange.com/images/site_images/';
    IMAGE_PATH = 'https://img.vasudevexchange.com/images/';
    LOGO_PATH = 'https://img.vasudevexchange.com/images/logo/';
    A_BASEURL = 'https://backend2.vasudevexchange.com/admin/v-1.0.0/';
    W_BASEURL = 'https://backend2.vasudevexchange.com/web/v-1.0.0/';
    MatchAPI = 'https://backend2.vasudevexchange.com/admin/v-1.0.0/';
}

const global = {
    IMAGE_PATH:IMAGE_PATH,
    LOGO_PATH:LOGO_PATH,
    W_BASEURL : W_BASEURL,
    ENCRYPTION_KEY:ENCRYPTION_KEY,
    A_BASEURL : A_BASEURL,
    STATIC_IMAGE_PATH:STATIC_IMAGE_PATH,
    MatchAPI:MatchAPI,
    delayTime:delayTime,
    SCORECARD:SCORECARD,
    LIVETV:LIVETV,
    SITEURL:SITEURL
}


export default global;
